import { api } from './httpService'
import { Bot } from '../types/models/bot'
import { Marker } from '../types/models/chart'

export const botService = {
  getAll: async (): Promise<Bot[]> => {
    const { data } = await api.get('/bot')
    return data
  },

  create: async (payload: Omit<Bot, 'id' | 'userId'>): Promise<Bot> => {
    const { data } = await api.post('/bot', payload)
    return data
  },

  edit: async (payload: Bot): Promise<Bot> => {
    const { data } = await api.put('/bot', payload)
    return data
  },

  getMarkers: async (botId: number): Promise<Marker[]> => {
    const { data } = await api.get(`/bot/${botId}/marker`)
    return data
  }
}

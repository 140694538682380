import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter } from 'react-router-dom'
import { ErrorProvider } from './providers/errorProvider'
import { Theme } from './app/theme'
import './locales/locale'
import { ConfirmContent } from './common/notify/confirmContent'

declare module 'notistack' {
  interface VariantOverrides {
    confirm: {
      onConfirm: () => void
    },
  }
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)


root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Theme>
          <SnackbarProvider autoHideDuration={5000} Components={{ confirm: ConfirmContent }}>
            <ErrorProvider>
              <App/>
            </ErrorProvider>
          </SnackbarProvider>
        </Theme>
      </Provider>
    </BrowserRouter>
  // </React.StrictMode>
)

reportWebVitals()

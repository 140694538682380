import { FC, ReactNode, useEffect, useMemo, useRef } from 'react'
import { Box, Paper } from '@mui/material'
import { formatChartData } from '../../../utils/chartDataFormat'
import { useChartState } from '../../../store/chart/slice'
import { getChartColor } from '../../../utils/utils'
import { useAppState } from '../../../store/app/slice'
import { ChartGenerator } from '../../../utils/chartGenerator'
import { useMarketState } from '../../../store/market/slice'

interface ChartProps {
  toolbar?: ReactNode
}

export const MultipleChart: FC<ChartProps> = ({ toolbar }) => {
  const chartContainerRef = useRef<HTMLDivElement | null>(null)
  const { isDark } = useAppState()
  const { chartType, normalizeData, chartsData } = useChartState()
  const { getMarket } = useMarketState()
  const formattedChartsData = useMemo(() => formatChartData(chartsData, chartType, normalizeData, 'bid'), [chartsData, chartType, normalizeData])

  useEffect(() => {
    if (formattedChartsData.length === 0 || !chartContainerRef.current) return

    const chart = new ChartGenerator(chartContainerRef.current, normalizeData, isDark).chart

    formattedChartsData.forEach((formattedChart, index) => {
      const market = getMarket(chartsData[index].symbol)
      if (chartType === 'candlestick') {
        const candlestickSeries = chart.addCandlestickSeries({
          priceLineWidth: 2,
          priceLineColor: '#4994EC',
          priceFormat: { minMove: market?.tickSize }
        })
        candlestickSeries.setData(formattedChart)
      } else if (chartType === 'line') {
        const color = getChartColor(index)
        const lineSeriesCurrent = chart.addLineSeries({
          priceLineWidth: 2,
          priceLineColor: color,
          color,
          priceFormat: { minMove: market?.tickSize }
        })
        lineSeriesCurrent.setData(formattedChart)
      }
    })

    return () => {
      chart.remove()
    }
  }, [formattedChartsData, chartType, normalizeData, isDark, chartsData, getMarket])

  return (
    <>
      <Paper sx={{ height: '100%', px: 1, pt: 1 }} variant="outlined">
        <Box height={35}>
          {toolbar}
        </Box>
        <Box ref={chartContainerRef} sx={{ height: 'calc(100% - 35px)' }}/>
      </Paper>
    </>
  )
}

import { FC, useState } from 'react'
import { Box, Dialog, Paper } from '@mui/material'
import { SettingsTabs } from './settingsTabs'
import { P1 } from '../../../common/typography'
import { SettingsTab } from '../../../types/models/common'
import { AppSettings } from './tabs/appSettings'
import { AccountSettings } from './tabs/accountSettings'


interface SettingsDialogProps {
  open: boolean
  onClose: () => void
}


export const SettingsDialog: FC<SettingsDialogProps> = ({ open, onClose }) => {
  const [currentTab, setCurrentTab] = useState<SettingsTab>('app')
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Paper sx={{ p: 3, minHeight: '50vh' }}>
        <Box className="border-b" mb={2} display="flex">
          <P1>Settings</P1>
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <SettingsTabs currentTab={currentTab} onChange={setCurrentTab}/>
          </Box>
          {currentTab === 'app' && <AppSettings/>}
          {currentTab === 'account' && <AccountSettings/>}
        </Box>
      </Paper>
    </Dialog>
  )
}

import { AppDispatch } from '../store'
import { newsActions } from './slice'
import { isAxiosError } from 'axios'
import { newsService } from '../../services/newsService'

const errorHandler = (error: any, dispatch: AppDispatch) => {
  let errorMessage: string
  dispatch(newsActions.setLoading(false))
  if (isAxiosError(error)) {
    errorMessage = error.response?.data.detail || error.response
  } else if (error.message) {
    errorMessage = error.message
  } else {
    errorMessage = 'Something went wrong...'
  }
  dispatch(newsActions.setError(errorMessage))
  throw new Error(errorMessage)
}

export const loadNews = (categoryId?: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(newsActions.setLoading(true))
    const categories = await newsService.getCategories()
    dispatch(newsActions.setCategories(categories))
    const tags = await newsService.getTags(categoryId)
    dispatch(newsActions.setTags(tags))
    const news = await newsService.getAll(categoryId)
    dispatch(newsActions.setNews(news))
    dispatch(newsActions.setLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}

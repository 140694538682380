import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import { AuthProviderButtons } from './authProviderButtons'
import { P1 } from '../../common/typography'


export const AuthLayout: FC = () => {
  return (
    <Box width="max-content" px={8} py={3} mx="auto" mt={8} className="rounded border">
      <Outlet/>
      <Box display="flex" className="border-t" mt={2}>
        <P1 width="100%" alignSelf="center">Or authorize with</P1>
        <AuthProviderButtons/>
      </Box>
    </Box>
  )
}

import { FC, SyntheticEvent, useState } from 'react'
import { MenuItem, Paper, Tab, Tabs } from '@mui/material'
import SsidChartIcon from '@mui/icons-material/SsidChart'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import SettingsIcon from '@mui/icons-material/Settings'
import SmartButtonIcon from '@mui/icons-material/SmartButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { P1 } from '../../common/typography'
import { SettingsDialog } from './settings/settingsDialog'


export const Toolbar: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [settingsOpened, setSettingsOpened] = useState(false)

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    navigate(newValue)
  }


  return (
    <Paper sx={{ mr: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '90vh' }}
           elevation={0}>
      <Tabs
        orientation="vertical"
        value={location.pathname.split('/').filter(n => n)?.at(1) || 'analytics'}
        onChange={handleChange}
      >
        <Tab sx={{ width: 'min-content' }}
             value="analytics"
             label={<><SsidChartIcon/><P1 fontSize={10}>Analytics</P1></>}
        />
        <Tab sx={{ width: 'min-content', mb: 'auto' }}
             value="prediction"
             label={<><AutoGraphIcon/><P1 fontSize={10} width="max-content">AI prediction</P1></>}
        />
        <Tab sx={{ width: 'min-content', mb: 'auto' }}
             value="bots"
             onClick={() => navigate('/trading/bots')}
             label={<><SmartButtonIcon/><P1 fontSize={10} width="max-content">AI trade</P1></>}
        />
      </Tabs>
      <MenuItem sx={{ flexDirection: 'column' }} onClick={() => setSettingsOpened(true)}>
        <SettingsIcon/>
        <P1 fontSize={10}>Settings</P1>
      </MenuItem>
      <SettingsDialog open={settingsOpened} onClose={() => setSettingsOpened(false)}/>
    </Paper>
  )
}

import { AppDispatch } from '../store'
import { setMarketError, setMarketLoading, setMarkets } from './slice'
import { marketService } from '../../services/marketService'

const errorHandler = (error: any, dispatch: AppDispatch) => {
  console.log(error)
  dispatch(setMarketError('ERROR'))
  dispatch(setMarketLoading(false))
}
export const loadMarkets = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setMarketLoading(true))
    const markets = await marketService.getAll()
    dispatch(setMarkets(markets))
    dispatch(setMarketLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}


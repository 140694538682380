import { Children, CSSProperties, FC, ReactNode } from 'react'
import './styles.css'
import { Panel, PanelGroup } from 'react-resizable-panels'
import { ResizeHandle } from './resizeHandle'

interface PanelsProps {
  children: ReactNode
  autoSaveId: string,
  containerHeight?: CSSProperties['height']
  direction?: 'vertical' | 'horizontal'
}

export const Panels: FC<PanelsProps> = (props) => {
  const { children, autoSaveId, direction = 'vertical', containerHeight = '100vh' } = props
  return (
    <div style={{ height: containerHeight, width: '100%'}}>
      <PanelGroup autoSaveId={autoSaveId} direction={direction} disablePointerEventsDuringResize>
        {Children.map(children, (child, index) => (
          <>
            <Panel key={index}>
              <div className="panels-content">
                {child}
              </div>
            </Panel>
            {Children.count(children) !== index + 1 && <ResizeHandle/>}
          </>
        ))}
      </PanelGroup>
    </div>
  )
}

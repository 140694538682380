import { FC, useState } from 'react'
import './styles.scss'
import { Box } from '@mui/material'
import { P3 } from '../../common/typography'
import { KitButton } from '../../common/ui-kit/kitButton'

export const CookiesPolicyPopUp: FC = () => {
  const [open, setOpen] = useState(true)
  const POLICY_KEY = 'cookies-policy'
  const policyAccepted = localStorage.getItem(POLICY_KEY) === 'accepted'

  const handleAccept = () => {
    setOpen(false)
    setTimeout(() => {
      localStorage.setItem(POLICY_KEY, 'accepted')
    }, 700)
  }


  return (
    <>
      {
        !policyAccepted &&
        <div className={`slide-top-element ${!open && 'slide-top-hide'}`}>
          <Box mb={{ xs: '10px', sm: 0 }}>
            <P3 component="span" fontWeight="400" alignSelf="center">
              CookiesPolicyPopupContent
            </P3>
            <P3 component="span" ml="5px">
              <a href="https://cookiepedia.co.uk/giving-consent-to-cookies" className="link" target="_blank">
                More info
              </a>
            </P3>
          </Box>

          <Box display="flex" gap="15px" alignItems="center">
            <a href="/info/cookies_policy" target="_blank">
              <KitButton fullWidth sx={{ height: 'min-content', width: 'max-content' }}>
                Cookie policy
              </KitButton>
            </a>
            <KitButton onClick={handleAccept} fullWidth sx={{ height: 'min-content', width: 'max-content' }}>
              Accept all
            </KitButton>
          </Box>
        </div>
      }
    </>
  )
}

import { FC } from 'react'
import { AppContainer } from '../../common/appContainer'
import { H3, P1, P2 } from '../../common/typography'
import { KitButton } from '../../common/ui-kit/kitButton'
import { Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../store/store'
import { useAuthState } from '../../store/auth/slice'
import { startTrial } from '../../store/auth/actions'

export const SubscriptionPage: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { isAuth, subscription, isSubscriptionActive } = useAuthState()

  const handleStartTrial = () => {
    if (!isAuth) {
      return navigate('/auth/login', { state: { referrer: location } })
    }
    if (!subscription) {
      dispatch(startTrial())
    }

  }

  return (
    <AppContainer mt="40px">
      <H3 mb="30px">You need a subscription to access the trading features</H3>

      {!subscription && <>
        <H3 mb="5px">Trial</H3>
        <Box display="flex" gap="15px" mb="20px">
          <P1 alignSelf="center">free 5-day trial</P1>
          <KitButton color="primary" onClick={handleStartTrial}>Try it now</KitButton>
        </Box>
      </>}

      {subscription && <>
        <Box mb="20px">
          <H3 mb="5px">Subscription ({isSubscriptionActive ? 'active' : 'inactive'})</H3>
          <P2>Your subscription expires on {new Date(subscription?.expires).toLocaleString()}</P2>
        </Box>
      </>}

      <H3 mb="5px">Pricing</H3>
      <P2 color="var(--accent-gray)">coming soon...</P2>
    </AppContainer>
  )
}

import { api } from './httpService'
import { Category, News, Tag } from '../types/models/news'

export const newsService = {
  getAll: async (categoryId?: number): Promise<News[]> => {
    const { data } = await api.get('/news', { params: { category_id: categoryId } })
    return data
  },
  getCategories: async (): Promise<Category[]> => {
    const { data } = await api.get('/news/categories')
    return data
  },
  getTags: async (categoryId?: number): Promise<Tag[]> => {
    const { data } = await api.get('/news/tags', { params: { category_id: categoryId } })
    return data
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Platform } from '../../types/models/user'
import { useAppSelector } from '../store'
import { localStorageService } from '../../services/localStorageService'

export interface PlatformState {
  platforms: Platform[]
  userPlatforms: Platform[]
  currentPlatform: Platform | null
  loading: boolean
  error: string | null
}

const initialState: PlatformState = {
  platforms: [],
  userPlatforms: [],
  currentPlatform: null,
  loading: true,
  error: null
}

export const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    setPlatformLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setPlatformError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload
    },
    setPlatforms: (state, { payload }: PayloadAction<Platform[]>) => {
      state.platforms = payload
    },
    setUserPlatforms: (state, { payload }: PayloadAction<Platform[]>) => {
      state.userPlatforms = payload
    },
    addUserPlatform: (state, { payload }: PayloadAction<Platform>) => {
      state.userPlatforms.push(payload)
    },
    setCurrentPlatform: (state, { payload }: PayloadAction<Platform>) => {
      state.currentPlatform = payload
      localStorageService.setCurrentPlatformName(payload)
    }
  }
})


export const {
  setPlatforms,
  setPlatformError,
  setPlatformLoading,
  setUserPlatforms,
  setCurrentPlatform,
  addUserPlatform
} = platformSlice.actions

export const PlatformReducer = platformSlice.reducer

export const usePlatformState = () => {
  const state = useAppSelector(s => s.platform)
  return { ...state }
}

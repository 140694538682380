import { FC, SyntheticEvent } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { P1 } from '../../../common/typography'
import TuneIcon from '@mui/icons-material/Tune'
import PersonIcon from '@mui/icons-material/Person'
import { SettingsTab } from '../../../types/models/common'

interface SettingsTabsProps {
  currentTab: SettingsTab
  onChange: (tab: SettingsTab) => void
}

export const SettingsTabs: FC<SettingsTabsProps> = ({ currentTab, onChange }) => {

  const handleChangeTab = (e: SyntheticEvent, newValue: SettingsTab) => {
    onChange(newValue)
  }
  return (
    <Tabs
      value={currentTab}
      orientation="vertical"
      onChange={handleChangeTab}
    >
      <Tab
        sx={{ alignItems: 'start' }}
        value="app"
        label={<Box display="flex"><TuneIcon/><P1 variant="caption" alignSelf="center" ml={1}>App</P1></Box>}
      />

      <Tab
        sx={{ alignItems: 'start' }}
        value="account"
        label={<Box display="flex"><PersonIcon/><P1 variant="caption" alignSelf="center" ml={1}>Account</P1></Box>}
      />
    </Tabs>
  )
}

import { auth, ProviderType } from './firebase'
import { AuthProviderName } from '../../types/models/user'
import { AuthByProviderBody } from '../../types/requests/authRequestTypes'
import { signInWithPopup } from 'firebase/auth'

export const firebaseService = {
  authByProvider: async (provider: ProviderType, providerName: AuthProviderName): Promise<AuthByProviderBody> => {
    const result = await signInWithPopup(auth, provider)
    if (result.user.email && result.user.displayName) {
      return {
        email: result.user.email,
        name: result.user.displayName,
        provider: providerName,
        accessToken: await result.user.getIdToken(false)
      }
    } else {
      throw new Error('UNEXPECTED_PROVIDER_ERROR')
    }
  }
}

import { FC, useCallback, useEffect, useState } from 'react'
import { newsActions, useNewsState } from '../../store/news/slice'
import { useAppDispatch } from '../../store/store'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  IconButton,
  Menu,
  Switch,
  Tooltip
} from '@mui/material'
import { NotificationsIcon, NotificationsOffIcon } from '../../common/assets/icons'
import { H3, P2, P3 } from '../../common/typography'
import { Category, News } from '../../types/models/news'
import { localStorageService } from '../../services/localStorageService'
import { browserNotification, convertKeysToCamelCase } from '../../utils/utils'
import { env } from '../../constants/envVars'

interface NewsNotificationsBtnProps {
  categoryId?: number
}

export const NewsNotificationsBtn: FC<NewsNotificationsBtnProps> = ({ categoryId }) => {
  const dispatch = useAppDispatch()
  const { categories, loading } = useNewsState()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [enabledCategoryNames, setEnabledCategoryNames] = useState<string[]>(localStorageService.getNewsNotificationsCategories())
  const [newsWs, setNewsWs] = useState<WebSocket | null>(null)
  const [permission, setPermission] = useState<NotificationPermission>()

  const categoryNames = categories.map(c => c.name)

  const isDenied = permission === 'denied'
  const isConnected = newsWs && newsWs.readyState === newsWs.OPEN
  const isSupported = 'Notification' in window
  const isEnabled = enabledCategoryNames.length > 0

  let tooltipMessage = 'Notifications enabled'
  if (!isSupported) {
    tooltipMessage = 'Notifications are not supported by your browser'
  } else if (!isEnabled) {
    tooltipMessage = 'Notifications disabled'
  } else if (isDenied) {
    tooltipMessage = 'Notifications are denied for this site. To enable notifications, go to your browser settings and allow notifications'
  } else if (!isConnected) {
    tooltipMessage = 'Connection lost'
  }
  const handleNotify = useCallback((news: News) => {
    const category = categories.find(c => c.id === news.categoryId)
    if (category && enabledCategoryNames.includes(category.name)) {
      browserNotification(`${news.symbol} (${news.predictionPercent}%)`)
    }
  }, [categories, enabledCategoryNames])

  useEffect(() => {
    console.log('newsWs', newsWs)
  }, [newsWs])

  const connectToWs = () => {
    const ws = new WebSocket(`${env.WS_API_ENDPOINT}/news/subscribe`)
    ws.onopen = () => {
      ws.send('subscribe')
      console.log('subscribe to news')
    }
    ws.onmessage = (event) => {
      const news: News = convertKeysToCamelCase(JSON.parse(event.data))
      handleNotify(news)
      if (!categoryId || news.categoryId === categoryId) {
        dispatch(newsActions.addNews(news))
      }
    }
    ws.onerror = () => {
      ws.close()
    }
    ws.onclose = () => {
      console.log('connection to news WS closed, reconnect in 3 second')
      setTimeout(function () {
        connectToWs()
      }, 3000)
    }
    return ws
  }

  useEffect(() => {
    if (('Notification' in window)) {
      Notification?.requestPermission().then(p => setPermission(p))
    }
  }, [])

  useEffect(() => {
    if (newsWs || loading) return
    const ws = connectToWs()
    setNewsWs(ws)
    return () => {
      if (newsWs) {
        ws.close()
        setNewsWs(null)
      }

    }
  }, [categoryId, connectToWs, dispatch, handleNotify, loading, newsWs, setNewsWs])

  const toggleAllNotifications = () => {
    if (enabledCategoryNames.length === 0) setEnabledCategoryNames(categoryNames)
    else setEnabledCategoryNames([])
  }

  const toggleCategoryNotification = (category: Category) => {
    if (enabledCategoryNames.includes(category.name)) {
      setEnabledCategoryNames(prev => prev.filter(name => name !== category.name))
    } else {
      setEnabledCategoryNames(prev => [...prev, category.name])
    }
  }

  useEffect(() => {
    localStorageService.setNewsNotificationsCategories(enabledCategoryNames)
  }, [enabledCategoryNames])

  return (
    <>
      <Tooltip title={tooltipMessage}>
        <div>
          <IconButton
            onClick={e => setAnchorEl(e.currentTarget)}
            sx={{ 'path': { stroke: isDenied ? 'var(--accent-gray)' : undefined } }}
          >
            {isEnabled ? <NotificationsIcon/> : <NotificationsOffIcon/>}
          </IconButton>
        </div>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
        <Accordion expanded={isEnabled} elevation={0}>
          <AccordionSummary>
            <Box>
              <H3 alignSelf="center" mr="auto">Notifications</H3>
              <P3 color="'var(--accent-gray)'">{loading ? 'Connecting...' : tooltipMessage}</P3>
            </Box>
            {loading ? <CircularProgress/> :
              <Switch checked={isEnabled} onChange={toggleAllNotifications} sx={{ ml: '10px' }}/>}
          </AccordionSummary>
          <AccordionDetails>
            {categories.map(category => (
              <Box key={category.id} display="flex" justifyContent="space-between">
                <P2 alignSelf="center" mr="auto" className="capitalize-first">{category.name}</P2>
                <Switch
                  checked={enabledCategoryNames.includes(category.name)}
                  onChange={() => toggleCategoryNotification(category)}
                />
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      </Menu>
    </>
  )
}

import { FC } from 'react'
import { Box, BoxProps } from '@mui/material'

export const AppContainer: FC<BoxProps> = ({ children, sx, ...props }) => {
  return (
    <Box mx={{ xs: '30px', sm: '75px', md: '100px', lg: '40px' }} sx={{ transition: 'all .2s', ...sx }} {...props}>
      {children}
    </Box>
  )
}

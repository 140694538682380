import React, { FC, ReactNode, useEffect, useMemo, useRef } from 'react'
import { useChartState } from '../../../store/chart/slice'
import { formatChartData } from '../../../utils/chartDataFormat'
import { useAppState } from '../../../store/app/slice'
import { Box, Paper } from '@mui/material'
import { useMarketState } from '../../../store/market/slice'
import { ChartGenerator } from '../../../utils/chartGenerator'
import { UTCTimestamp } from 'lightweight-charts'
import { marketService } from '../../../services/marketService'
import { IntervalsInSec } from '../../../constants/constants'


interface PredictionChartProps {
  toolbar?: ReactNode
}

export const PredictionChart: FC<PredictionChartProps> = ({ toolbar }) => {
  const { isDark } = useAppState()
  const { chartsData, interval } = useChartState()
  const { getMarket } = useMarketState()
  const formattedBidChartData = useMemo(() => formatChartData(chartsData, 'line', false, 'bid')[0], [chartsData])
  const formattedAskChartData = useMemo(() => formatChartData(chartsData, 'line', false, 'ask')[0], [chartsData])
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const market = getMarket(chartsData[0]?.symbol)
  const connectionRef = useRef<WebSocket | null>(null)


  useEffect(() => {
    if (!formattedAskChartData || !formattedBidChartData || !chartContainerRef.current || !market) return
    const chart = new ChartGenerator(chartContainerRef.current, false, isDark).chart
    const askColor = '#51A39A'
    const bidColor = '#DD5E56'

    const askSeries = chart.addLineSeries({
      priceLineWidth: 2,
      priceLineColor: askColor,
      color: askColor,
      priceFormat: { minMove: market?.tickSize }
    })
    askSeries.setData(formattedAskChartData)

    const bidSeries = chart.addLineSeries({
      priceLineWidth: 2,
      priceLineColor: bidColor,
      color: bidColor,
      priceFormat: { minMove: market?.tickSize }
    })
    bidSeries.setData(formattedBidChartData)

    if (!connectionRef.current) {
      connectionRef.current = marketService.subscribeToLineMarket(market.symbol, (data) => {
        const intervalInSec = IntervalsInSec[interval]
        const time = Math.floor((data.timestamp / 1000) / intervalInSec) * intervalInSec
        askSeries.update({ time: time as UTCTimestamp, value: data.ask })
        bidSeries.update({ time: time as UTCTimestamp, value: data.bid })
      })
    }

    return () => {
      chart.remove()
      if (connectionRef.current && connectionRef.current.readyState > 0) {
        connectionRef.current.close()
        connectionRef.current = null
      }

    }
  }, [formattedAskChartData, formattedBidChartData, isDark, chartsData, market, interval])

  return <>
    <Paper sx={{ height: '100%', px: 1, pt: 1 }} variant="outlined">
      <Box height={35}>
        {toolbar}
      </Box>
      <Box ref={chartContainerRef} sx={{ height: 'calc(100% - 35px)' }}/>
    </Paper>
  </>
}


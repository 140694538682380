import React, { FC, useEffect } from 'react'
import { Markets } from './market/markets'
import { Panels } from '../../common/panels/panels'
import { setCharts, toggleNormalizeData, useChartState } from '../../store/chart/slice'
import { localStorageService } from '../../services/localStorageService'
import { loadChartData } from '../../store/chart/actions'
import { useAppDispatch } from '../../store/store'
import { ChartToolBar } from './chart/chartToolBar'
import { PredictionChart } from './chart/predictionChart'

export const PredictionPage: FC = () => {
  const dispatch = useAppDispatch()
  const { chartsData, normalizeData } = useChartState()

  useEffect(() => {
    const firstChartSymbol = localStorageService.getSelectedCharts()[0]
    if (firstChartSymbol) {
      const existingChartData = chartsData.find(data => data.symbol === firstChartSymbol)
      if (!existingChartData) dispatch(loadChartData(firstChartSymbol, 'single'))
      else dispatch(setCharts([existingChartData]))
    }

    if (normalizeData) {
      dispatch(toggleNormalizeData())
    }

  }, [dispatch, normalizeData])

  return (
    <>
      <Panels autoSaveId="trading" containerHeight="90vh">
        <Markets selection="single"/>
        <PredictionChart
          toolbar={<ChartToolBar normalizeControl={false} typeControl={false}/>}
        />
      </Panels>
    </>
  )
}

import React, { FC } from 'react'
import { PanelResizeHandle } from 'react-resizable-panels'

export const ResizeHandle: FC<{ id?: string }> = ({ id }) => {
  return (
    <PanelResizeHandle className="panels-resize-handle-outer" id={id}>
      <div className="panels-resize-handle-inner">
        <svg className="panels-resize-handle-icon" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z"
          />
        </svg>
      </div>
    </PanelResizeHandle>
  )
}

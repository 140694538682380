import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Typography, TypographyProps } from '@mui/material'

export interface TextProps extends TypographyProps {
  untranslate?: boolean
}

export const Text: FC<TextProps> = ({ children, untranslate, ...props }) => {
  const { t } = useTranslation()
  return (
    <Typography {...props}>
      {
        untranslate
          ? children
          : <Trans t={t}>{children}</Trans>
      }
    </Typography>
  )
}

import { AppDispatch } from '../store'
import {
  addUserPlatform,
  setCurrentPlatform,
  setPlatformError,
  setPlatformLoading,
  setPlatforms,
  setUserPlatforms
} from './slice'
import { platformService } from '../../services/platformService'
import { localStorageService } from '../../services/localStorageService'


const errorHandler = (error: any, dispatch: AppDispatch) => {
  console.log(error)
  dispatch(setPlatformError('ERROR'))
  dispatch(setPlatformLoading(false))
  throw new Error(error)
}

export const loadAllPlatforms = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setPlatformLoading(true))
    const platforms = await platformService.getAll()
    dispatch(setPlatforms(platforms))
    dispatch(setPlatformLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}

export const loadUserPlatforms = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setPlatformLoading(true))
    const platforms = await platformService.getUserPlatforms()
    const currentPlatformName = localStorageService.getCurrentPlatformName()
    if (currentPlatformName) {
      const currentPlatform = platforms.find(p => p.name === currentPlatformName)
      if (currentPlatform) {
        dispatch(setCurrentPlatform(currentPlatform))
      }
    }
    dispatch(setUserPlatforms(platforms))
    dispatch(setPlatformLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}

export const assignPlatformToUser = (platformId: number, apiKey: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setPlatformLoading(true))
    const assignedPlatform = await platformService.assignToUser(platformId, apiKey)
    dispatch(addUserPlatform(assignedPlatform))
    dispatch(setPlatformLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}



import React, { FC, useEffect } from 'react'
import { Markets } from './market/markets'
import { MultipleChart } from './chart/multipleChart'
import { Panels } from '../../common/panels/panels'
import { localStorageService } from '../../services/localStorageService'
import { loadChartData } from '../../store/chart/actions'
import { useAppDispatch } from '../../store/store'
import { useChartState } from '../../store/chart/slice'
import { ChartToolBar } from './chart/chartToolBar'

export const AnalyticsPage: FC = () => {
  const dispatch = useAppDispatch()
  const { chartsData } = useChartState()

  useEffect(() => {
    const selectedCharts = localStorageService.getSelectedCharts()
    selectedCharts.forEach(symbol => {
      if (!chartsData.find(data => data.symbol === symbol)) dispatch(loadChartData(symbol, 'multiple'))
    })
  }, [dispatch])


  return (
    <Panels autoSaveId="trading" containerHeight="90vh">
      <Markets selection="multiple"/>
      <MultipleChart toolbar={<ChartToolBar/>}/>
    </Panels>
  )
}

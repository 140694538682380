import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Market, MarketFilter } from '../../types/models/chart'
import { useAppSelector } from '../store'

export interface MarketState {
  markets: Market[]
  loading: boolean
  error: string | null
  filter: MarketFilter
}

const initialState: MarketState = {
  markets: [],
  loading: true,
  error: null,
  filter: { assetType: null, name: null }
}

export const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setMarketLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setMarketError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload
    },
    setMarkets: (state, { payload }: PayloadAction<Market[]>) => {
      state.markets = payload
    },
    setFilterByType: (state, { payload }: PayloadAction<string | null>) => {
      state.filter.assetType = payload
    },
    setFilterByName: (state, { payload }: PayloadAction<string | null>) => {
      state.filter.name = payload
    }
  }
})


export const { setMarketLoading, setMarketError, setMarkets, setFilterByType, setFilterByName } = marketSlice.actions

export const MarketReducer = marketSlice.reducer


export const useMarketState = () => {
  const state = useAppSelector(state => state.market)
  let markets = state.markets
  const currencies = state.markets?.reduce((acc: { type: string, currencies: string[] }[], market: Market) => {
    const index = acc.findIndex(a => a.type === market.assetType)
    if (index >= 0) acc[index].currencies.push(market.baseAsset)
    else acc.push({ type: market.assetType, currencies: [market.baseAsset] })
    return acc
  }, [])

  Object.entries(state.filter).forEach((filter) => {
    const filterKey = filter[0] as keyof MarketFilter
    const filterValue: string = filter[1]
    if (!filterValue) return

    if (filterKey === 'assetType') {
      markets = markets.filter(market => market.assetType === filterValue)
    }

    if (filterKey === 'name') {
      const searchWords = filterValue.toLowerCase().split(' ').map(word => word.trim())
      markets = markets.filter(market => {
        return searchWords.every(word => market.name.toLowerCase().includes(word))
      })
    }
  })

  const getMarket = (symbol: string) => {
    return state.markets.find(market => market.symbol === symbol)
  }
  return { ...state, markets, currencies, getMarket }
}

import _ from 'lodash'
import { useState } from 'react'

interface Sort<T> {
  path: keyof T;
  order: 'asc' | 'desc'
}

export const useSort = <T>(items: T[], defaultPath?: keyof T, defaultOrder?: 'asc' | 'desc') => {
  const [sortBy, setSortBy] = useState<Sort<T> | null>(defaultPath ? {
    path: defaultPath,
    order: defaultOrder || 'asc'
  } : null)

  const sortedItems: T[] = _.orderBy(items, [sortBy?.path], [sortBy?.order || 'asc']) as T[]
  const handleSort = (path: keyof T) => {
    if (path === sortBy?.path) {
      setSortBy({ path, order: sortBy.order === 'asc' ? 'desc' : 'asc' })
    } else {
      setSortBy({ path, order: 'asc' })
    }
  }

  return { sortedItems, handleSort, sortBy }
}

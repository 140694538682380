import { FC, MouseEvent, useState } from 'react'
import { Box, Chip, IconButton, Paper, Stack, Typography } from '@mui/material'
import './styles.css'
import { EditBotDialog } from './editBotDialog'
import { useNavigate } from 'react-router-dom'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { Bot } from '../../../types/models/bot'

interface BotCardProps {
  bot: Bot
}

export const BotCard: FC<BotCardProps> = ({ bot }) => {
  const navigate = useNavigate()
  const [editDialogOpen, setEditDialogOpen] = useState(false)

  const handleEdit = (event: MouseEvent) => {
    event.stopPropagation()
    setEditDialogOpen(true)
  }
  return (
    <>
      <Paper className="bot-card" variant="outlined" onClick={() => navigate(`/trading/bots/${bot.id}`)}>
        <IconButton sx={{ position: 'absolute', right: 0, top: 3, zIndex: 100 }} onClick={handleEdit}>
          <SettingsOutlinedIcon fontSize="small"/>
        </IconButton>
        <Stack direction="row" spacing={2} pb={1} position="relative" className="border-b">
            <Typography className="bot-card__currency">{bot.symbol}</Typography>
            {bot.short && <Chip label="short" size="small"/>}
            {bot.long && <Chip label="long" size="small"/>}
          </Stack>

        <Stack direction="row" spacing={2} justifyContent="space-between">
            <Typography>{bot.type}</Typography>
            {bot.trailingStop && <Typography className="bot-card__stop-loss">SL: {bot.trailingStop}</Typography>}
            <Typography color="primary" variant="button" fontWeight="bold">{bot.interval}</Typography>
          </Stack>
          <Typography fontSize={18} color="text.secondary">{bot.title}</Typography>
          <Box display="flex">

          </Box>
        </Paper>
      <EditBotDialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} bot={bot}/>
    </>
  )
}

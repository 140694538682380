import React, { FC, useState } from 'react'
import { Box, IconButton, Paper, Tabs } from '@mui/material'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import { useAppDispatch } from '../../store/store'
import { setAppTheme, useAppState } from '../../store/app/slice'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { H3 } from '../../common/typography'
import { AppContainer } from '../../common/appContainer'
import { useNewsState } from '../../store/news/slice'
import { KitTab } from '../../common/ui-kit/kitTab'
import { NewsNotificationsBtn } from './newsNotificationsBtn'


export const NewsNavbar: FC = () => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const { theme, isDark } = useAppState()
  const { categories } = useNewsState()
  const [currentCategoryName, setCurrentCategoryName] = useState(params.categoryName)

  const currentCategory = categories.find(c => c.name === params.categoryName)
  const handleChangeMode = () => {
    dispatch(setAppTheme(isDark ? 'light' : 'dark'))
  }

  const handleChangeCategory = (categoryName?: string) => {
    setCurrentCategoryName(categoryName || undefined)
    navigate(`/news/${categoryName || ''}`)
  }

  console.log('Notification in window:', 'Notification' in window)

  return (

    <Paper elevation={0} sx={{ position: 'sticky', top: 0, zIndex: 100 }}>
      <AppContainer display="flex" height={{ xs: '65px', lg: '75px' }}>
        <Box display="flex" gap={{ md: '50px', lg: '75px', xl: '0' }} width="100%">
          <NavLink to="/" style={{ display: 'flex' }}>
            <H3 alignSelf="center" width="max-content">XMarketGPT</H3>
          </NavLink>

          <Box display={{ xs: 'none', md: 'flex' }} width="100%" justifyContent={{ xl: 'center' }}>
            <Tabs value={currentCategoryName || ''} sx={{ mt: { xs: '10px', lg: '15px' } }}>
              {categories.length && <KitTab value="" label="All news" onClick={() => handleChangeCategory()}/>}
              {categories.map(category => (
                <KitTab key={category.id} value={category.name} label={category.name}
                        onClick={() => handleChangeCategory(category.name)}/>
              ))}
            </Tabs>
          </Box>

        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" ml="auto">
          <NewsNotificationsBtn categoryId={currentCategory?.id}/>
          <IconButton onClick={handleChangeMode} size="small">
            {theme === 'light' && <LightModeIcon sx={{ color: '#FAE100' }}/>}
            {theme === 'dark' && <DarkModeIcon/>}
          </IconButton>
        </Box>
      </AppContainer>
    </Paper>
  )
}

import { forwardRef } from 'react'
import { Text, TextProps } from './text'

export interface P1Props extends TextProps {
}

export const P1 = forwardRef<HTMLParagraphElement, P1Props>(({ children, ...props }, ref) => {

  return (
    <Text
      ref={ref}
      fontSize={{ xs: '16px', sm: '20px', lg: '22px' }}
      lineHeight="120%"
      fontWeight="400"
      fontFamily="'Montserrat', sans-serif;"
      {...props}
    >
      {children}
    </Text>
  )
})

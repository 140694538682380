import { FC } from 'react'
import { useBotState } from '../../../store/bot/slice'
import { Grid } from '@mui/material'
import { AddBotCard } from './addBotCard'
import { BotCard } from './botCard'

export const BotsPage: FC = () => {
  const { bots } = useBotState()
  return (
    <Grid container rowSpacing={1} columnSpacing={1}>
      <Grid item>
        <AddBotCard/>
      </Grid>
      {
        bots.map(bot => (
          <Grid item key={bot.id}>
            <BotCard bot={bot}/>
          </Grid>
        ))
      }
    </Grid>
  )
}

import { FC } from 'react'
import { KitButton, KitButtonProps } from './ui-kit/kitButton'
import { ChevronDownIcon, ChevronUpIcon } from './assets/icons'

interface SortButtonProps extends KitButtonProps {
  order?: 'asc' | 'desc'
  selected?: boolean
}

export const SortButton: FC<SortButtonProps> = ({ order, selected, children, ...props }) => {
  return (
    <KitButton
      color={selected ? 'primary' : 'inherit'}
      endIcon={selected && order === 'asc' ? <ChevronUpIcon/> : <ChevronDownIcon/>}
      variant="text"
      {...props}
    >
      {children}
    </KitButton>
  )
}

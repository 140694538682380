import { forwardRef, ReactNode } from 'react'
import { SxProps, TextField, TextFieldProps, TextFieldVariants } from '@mui/material'


export interface KitInputProps extends Omit<TextFieldProps, 'variant'> {
  variant?: TextFieldVariants
  lite?: boolean
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  sx?: SxProps
}

export const KitInput = forwardRef<HTMLDivElement, KitInputProps>((props, ref) => {
  const { children, lite, sx, startAdornment, endAdornment, InputProps, ...otherProps } = props
  const color = lite ? 'var(--light)' : 'var(--dark)'
  const borderColorHover = lite ? 'var(--main-paper)' : 'var(--main-text)'
  const styles: Record<TextFieldVariants, SxProps> = {
    outlined: {
      '& .MuiOutlinedInput-root': {
        borderColor: 'var(--accent-gray)',
        borderRadius: '10px',
        color: color,
        '& fieldset': {
          borderColor: 'var(--gray)'
        },
        '&:hover fieldset': !props.disabled ? {
          borderColor: `${borderColorHover} !important`
        } : {},
        '&.Mui-focused fieldset': {
          borderColor: `${borderColorHover} !important`
        },
        '&::placeholder': {
          color: '#b9bdcb'
        }
      },
      ...sx
    },
    filled: {
      ...sx
    },
    standard: {
      ...sx
    }
  }

  return (
    <TextField
      ref={ref}
      sx={{ ...styles[props.variant || 'outlined'] }}
      fullWidth
      InputProps={{ startAdornment, endAdornment, ...InputProps }}
      {...otherProps}
    >
      {children}
    </TextField>
  )
})

import _ from 'lodash'

export const convertKeysToCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item))
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((acc: any, key) => {
      const camelCaseKey = _.camelCase(key)
      acc[camelCaseKey] = convertKeysToCamelCase(obj[key])
      return acc
    }, {})
  }
  return obj
}

export const convertKeysToSnakeCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item))
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((acc: any, key) => {
      const camelCaseKey = _.snakeCase(key)
      acc[camelCaseKey] = convertKeysToCamelCase(obj[key])
      return acc
    }, {})
  }
  return obj
}

export const getChartColor = (index: number) => {
  const colors = ['#4994EC', '#96E892', '#632D80', '#FF3838', '#FFC738', '#FE31DF', '#38FF7E', '#38F2FF', '#3F38FF', '#FCFF38']
  return colors[index]
}


export const browserNotification = (text: string) => {
  if (!('Notification' in window)) {
    console.log('Browser doesn\'t support the notification')
  } else if (Notification.permission === 'granted') {
    new Notification(text)
  } else if (Notification.permission !== 'denied') {
    Notification?.requestPermission().then(function (permission) {
      if (permission === 'granted' && Notification) {
        new Notification(text)
      }
    })
  }
}

import { FC } from 'react'
import { MarketsGrid } from './marketsGrid'
import { Divider, Paper } from '@mui/material'
import { MarketFilterPanel } from './marketFilterPanel'

interface MarketsProps {
  selection: 'multiple' | 'single'
}

export const Markets: FC<MarketsProps> = ({ selection }) => {

  return (
    <Paper sx={{ height: '100%', display: 'flex' }} variant="outlined">
      <MarketFilterPanel/>
      <Divider orientation="vertical"/>
      <MarketsGrid selection={selection}/>
    </Paper>
  )
}

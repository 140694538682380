import { FC, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { NewsNavbar } from '../navbar/newsNavbar'
import { loadNews } from '../../store/news/actions'
import { useAppDispatch } from '../../store/store'
import { useNewsState } from '../../store/news/slice'

export const NewsLayout: FC = () => {
  const dispatch = useAppDispatch()
  const { categories, loading } = useNewsState()
  const categoryName = useParams().categoryName
  const categoryId = categories.find(c => c.name === categoryName)?.id

  useEffect(() => {
    if (!loading) {
      dispatch(loadNews(categoryId))
    }
  }, [dispatch, categoryId])

  return (
    <>
      <NewsNavbar/>
      <Outlet/>
    </>
  )
}

export enum Intervals {
  MINUTE = '1m',
  FIVE_MINUTES = '5m',
  FIFTEEN_MINUTES = '15m',
  THIRTY_MINUTES = '30m',
  HOUR = '1h',
  FOUR_HOURS = '4h',
  DAY = '1d',
  WEEK = '1w',
}

export const IntervalsInSec = {
  [Intervals.MINUTE]: 60,
  [Intervals.FIVE_MINUTES]: 300,
  [Intervals.FIFTEEN_MINUTES]: 900,
  [Intervals.THIRTY_MINUTES]: 1800,
  [Intervals.HOUR]: 3600,
  [Intervals.FOUR_HOURS]: 14_400,
  [Intervals.DAY]: 86_400,
  [Intervals.WEEK]: 604_800
}


export enum Locales {
  RU = 'ru',
  EN = 'en'
}

export const PRIMARY_COLOR = '#58C174'

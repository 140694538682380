import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useAppSelector } from '../store'
import { Bot } from '../../types/models/bot'
import { Marker } from '../../types/models/chart'

export interface BotState {
  loading: boolean
  error: string | null
  bots: Bot[]
  markers: Marker[]
}

const initialState: BotState = {
  bots: [],
  loading: true,
  error: null,
  markers: []
}

export const botSlice = createSlice({
  name: 'bot',
  initialState,
  reducers: {
    setBotLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setBotError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload
    },
    setBots: (state, { payload }: PayloadAction<Bot[]>) => {
      state.bots = payload
    },
    addBot: (state, { payload }: PayloadAction<Bot>) => {
      state.bots = [...state.bots, payload]
    },
    setBot: (state, { payload }: PayloadAction<Bot>) => {
      state.bots = state.bots.map(bot => bot.id === payload.id ? payload : bot)
    },
    setBotMarkers: (state, { payload }: PayloadAction<Marker[]>) => {
      state.markers = payload
    },
    addBotMarker: (state, { payload }: PayloadAction<Marker>) => {
      state.markers = [payload, ...state.markers]
    }

  }
})


export const { setBotLoading, setBotError, setBots, addBot, setBot, setBotMarkers, addBotMarker } = botSlice.actions

export const BotReducer = botSlice.reducer

export const useBotState = () => {
  const state = useAppSelector(state => state.bot)
  return { ...state }
}



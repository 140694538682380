import { FC, useState } from 'react'
import { CircularProgress, Paper } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import './styles.css'
import { P1 } from '../../../common/typography'
import { EditBotDialog } from './editBotDialog'
import { useBotState } from '../../../store/bot/slice'

export const AddBotCard: FC = () => {
  const { loading } = useBotState()
  const [createDialogOpen, setCreateDialogOpen] = useState(false)

  const handleOpenCreateDialog = () => {
    if (!loading) {
      setCreateDialogOpen(true)
    }
  }

  return (
    <>
      <Paper className="add-bot-card" variant="outlined" onClick={handleOpenCreateDialog}>
        {loading ? <CircularProgress/> : <><AddIcon color="primary"/><P1 variant="caption">Add a bot</P1></>}
      </Paper>
      <EditBotDialog open={createDialogOpen} onClose={() => setCreateDialogOpen(false)}/>
    </>
  )
}

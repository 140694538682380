import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { NewsList } from './newsList'
import { H2 } from '../../common/typography'
import { AppContainer } from '../../common/appContainer'
import { KitInput } from '../../common/ui-kit/inputs/kitInput'
import { SearchIcon } from '../../common/assets/icons'
import { useParams } from 'react-router-dom'
import { TagList } from './tagList'
import { Tag } from '../../types/models/news'
import { ReactComponent as CloseIcon } from '../../common/assets/icons/svg/close.svg'
import { useNewsState } from '../../store/news/slice'

export const NewsPage: FC = () => {
  const params = useParams()
  const { categories } = useNewsState()
  const [searchString, setSearchString] = useState('')
  const [searchTag, setSearchTag] = useState<Tag | null>(null)
  const categoryName = categories.find(category => category.id === Number(params.categoryId))?.name || 'All'

  useEffect(() => {
    handleClearSearchInput()
  }, [categoryName])

  const handleChangeSearchString = (event: ChangeEvent<HTMLInputElement>) => {
    if (searchTag) {
      setSearchTag(null)
      setSearchString('')
    } else {
      setSearchString(event.target.value)
    }
  }
  const handleChangeSearchTag = (tag: Tag) => {
    setSearchTag(tag)
    setSearchString(`#${tag.name}`)
  }

  const handleClearSearchInput = () => {
    setSearchTag(null)
    setSearchString('')
  }


  return (
    <AppContainer mt={{ xs: '60px', sm: '80px' }}>
      <KitInput
        value={searchString}
        onChange={handleChangeSearchString}
        startAdornment={<SearchIcon style={{ marginRight: '5px' }}/>}
        endAdornment={<CloseIcon style={{ cursor: 'pointer' }} onClick={handleClearSearchInput}/>}
        placeholder="Search by symbol, hashtag"
      />
      <H2 my={{ xs: '40px', sm: '60px' }} textAlign="center" className="capitalize-first">{categoryName} news</H2>
      <TagList sx={{ mb: { xs: '20px', md: '30px' } }} activeTag={searchTag} onChangeActiveTag={handleChangeSearchTag}/>
      <NewsList searchString={searchString} searchTag={searchTag}/>
    </AppContainer>
  )
}

import { initializeApp } from "firebase/app"
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, GithubAuthProvider } from 'firebase/auth'
import { env } from '../../constants/envVars'

const firebaseConfig = {
  apiKey: env.FIREBASE_API_KEY,
  authDomain: "trading-platform-671bd.firebaseapp.com",
  projectId: "trading-platform-671bd",
  storageBucket: "trading-platform-671bd.appspot.com",
  messagingSenderId: "433975522320",
  appId: "1:433975522320:web:16ac2166dc1692b2ef8695",
  measurementId: "G-V8NP4LKX42"
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider()
export const githubProvider = new GithubAuthProvider()
export const facebookProvider = new FacebookAuthProvider()
export type ProviderType = GithubAuthProvider | GoogleAuthProvider | FacebookAuthProvider

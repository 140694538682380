import { FC, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'
import { setCurrentPlatform, usePlatformState } from '../../../../store/platform/slice'
import { P1 } from '../../../../common/typography'
import { useAppDispatch } from '../../../../store/store'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TButton } from '../../../../common/tButton'
import { useTranslation } from 'react-i18next'
import { assignPlatformToUser } from '../../../../store/platform/actions'
import { useSnackbar } from 'notistack'


export const AccountSettings: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { platforms, userPlatforms, currentPlatform } = usePlatformState()
  const { enqueueSnackbar: snackbar } = useSnackbar()
  const [addPlatformOpen, setAddPlatformOpen] = useState(false)
  const [newPlatformName, setNewPlatformName] = useState('-')
  const [apiKey, setApiKey] = useState('')

  const handleChangePlatform = (event: SelectChangeEvent) => {
    const newCurrentPlatform = userPlatforms.find(p => p.name === event.target.value)
    if (newCurrentPlatform) {
      dispatch(setCurrentPlatform(newCurrentPlatform))
    }
  }

  const handleChangeNewPlatform = (event: SelectChangeEvent) => {
    setNewPlatformName(event.target.value)
  }

  const handleSaveNewPlatform = () => {
    if (!newPlatformName || newPlatformName === '-' || !apiKey) return

    const newPlatform = platforms.find(p => p.name === newPlatformName)
    if (!newPlatform) return

    dispatch(assignPlatformToUser(newPlatform.id, apiKey))
      .then(() => {
        snackbar('Api key successfully added')
        setNewPlatformName('-')
        setApiKey('')
        setAddPlatformOpen(false)
      })
  }

  return (
    <Box width="100%">

      <Box mb={4} width="100%">
        <P1 mr={3}>platform</P1>
        <Select value={currentPlatform?.name || '-'} onChange={handleChangePlatform} fullWidth>
          <MenuItem value="-">
            <P1 ml={2}>not selected</P1>
          </MenuItem>
          {userPlatforms.map(userPlatform => (
            <MenuItem key={userPlatform.id} value={userPlatform.name}>
              <Box display="flex">
                {userPlatform.icon && <img src={userPlatform.icon} alt="en-icon" width={25} height={25}/>}
                <Typography ml={2}>{userPlatform.name}</Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Accordion sx={{ mb: 2 }} expanded={addPlatformOpen} onChange={() => setAddPlatformOpen(prev => !prev)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <P1>Add new platform</P1>
        </AccordionSummary>
        <AccordionDetails>
          <Select placeholder={t('select platform')} value={newPlatformName} onChange={handleChangeNewPlatform}
                  fullWidth>
            <MenuItem value="-">
              <P1 ml={2}>not selected</P1>
            </MenuItem>
            {platforms.map(platform => (
              <MenuItem
                key={platform.id} value={platform.name} disabled={!!userPlatforms.find(p => p.id === platform.id)}
              >
                <Box display="flex">
                  {platform.icon && <img src={platform.icon} alt="en-icon" width={25} height={25}/>}
                  <Typography ml={2}>{platform.name}</Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
          <P1 mt={1}>api key</P1>
          <Box display="flex" justifyContent="space-between">
            <TextField size="small" value={apiKey} onChange={e => setApiKey(e.target.value)}/>
            <TButton
              variant="outlined"
              disabled={!apiKey || !newPlatformName || newPlatformName === '-'}
              onClick={handleSaveNewPlatform}
            >
              add
            </TButton>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

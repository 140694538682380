import { ChangeEvent, FC } from 'react'
import { Box, IconButton, List, ListItemButton, ListSubheader, TextField, Typography } from '@mui/material'
import { setFilterByName, setFilterByType, useMarketState } from '../../../store/market/slice'
import RemoveIcon from '@mui/icons-material/Remove'
import { useAppDispatch } from '../../../store/store'
import { useTranslation } from 'react-i18next'
import { P1 } from '../../../common/typography'

export const MarketFilterPanel: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { currencies, filter } = useMarketState()

  const handleClearFilter = () => {
    dispatch(setFilterByType(null))
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilterByName(event.target.value))
  }

  return (
    <Box>
      <TextField size="small" placeholder={t('Search...')} fullWidth onChange={handleSearch}/>
      <List sx={{ width: 'max-content', overflow: 'auto', height: '75%' }} subheader={
        <ListSubheader sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {t('Market types')}
          {
            filter.assetType &&
            <IconButton sx={{ height: 20, width: 20, my: 'auto' }} color="error" onClick={handleClearFilter}>
              <RemoveIcon/>
            </IconButton
            >}
        </ListSubheader>
      }>
        {currencies.map(currency => (
          <ListItemButton
            key={currency.type}
            onClick={() => dispatch(setFilterByType(currency.type))}
            selected={filter.assetType === currency.type}
          >
            <P1 className="capitalize-first" mr={3}>{currency.type.toLowerCase().replace('_', ' ')}</P1>
            <Typography ml="auto" fontSize={14} color="#aaa">{currency.currencies.length}</Typography>

          </ListItemButton>
        ))}
      </List>
    </Box>
  )
}

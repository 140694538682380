import { FC } from 'react'
import { Box, Button, ButtonGroup } from '@mui/material'
import { Intervals } from '../../../constants/constants'
import { setChartType, setInterval, toggleNormalizeData } from '../../../store/chart/slice'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import { TButton } from '../../../common/tButton'

interface ChartToolBarProps {
  intervalControl?: boolean
  typeControl?: boolean
  normalizeControl?: boolean
}

export const ChartToolBar: FC<ChartToolBarProps> = (props) => {
  const { normalizeControl = true, typeControl = true, intervalControl = true } = props
  const dispatch = useAppDispatch()
  const { interval, chartType, normalizeData } = useAppSelector(state => state.chart)

  return (
    <Box display="flex">

      {
        intervalControl &&
        <ButtonGroup variant="text" size="small" sx={{ height: 25 }}>
          {Object.values(Intervals).map(i => (
              <Button key={i} variant={interval === i ? 'contained' : 'text'} onClick={() => dispatch(setInterval(i))}>
                {i}
              </Button>
            )
          )}
        </ButtonGroup>
      }

      {
        typeControl &&
        <ButtonGroup sx={{ height: 25, ml: 'auto' }}>
          <Button
            onClick={() => dispatch(setChartType('candlestick'))}
            variant={chartType === 'candlestick' ? 'contained' : 'outlined'}
          >
            <CandlestickChartIcon/>
          </Button>
          <Button
            onClick={() => dispatch(setChartType('line'))}
            variant={chartType === 'line' ? 'contained' : 'outlined'}
          >
            <ShowChartIcon/>
          </Button>
        </ButtonGroup>
      }

      {
        normalizeControl &&
        <TButton
          size="small"
          sx={{ height: 25, ml: 2 }}
          variant={normalizeData ? 'contained' : 'outlined'}
          onClick={() => dispatch(toggleNormalizeData())}
        >
          Normalize
        </TButton>
      }
    </Box>
  )
}

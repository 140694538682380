import React, { FC, ReactNode } from 'react'
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { useAppState } from '../store/app/slice'
import '../common/styles/colors.scss'

export const Theme: FC<{ children: ReactNode }> = ({ children }) => {
  const { theme } = useAppState()
  const darkPalette: ThemeOptions['palette'] = {
    text: {
      primary: '#FFFFFF'
    },
    primary: {
      main: '#FFB13D'
    },
    secondary: {
      main: '#77ADFF'
    },
    background: {
      default: '#030B25',
      paper: '#0E1A31'
    },
  }

  const palette = theme === 'dark' ? darkPalette : {}

  const appTheme = createTheme({
    palette: {
      mode: theme,
      text: {
        primary: '#050313'
      },
      primary: {
        main: '#E88C03',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#2F2A97'
      },
      background: {
        default: '#F4F6FF',
        paper: '#ffffff'
      },
      ...palette

    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 1024,
        lg: 1440,
        xl: 1920
      }
    },
    typography: {
      fontFamily: '\'Montserrat\', sans-serif;',
      fontWeightRegular: '400',
      fontWeightBold: '600',
      fontSize: 14,
      h1: {
        fontWeight: 'bold'
      },
      h2: {
        fontWeight: 'bold'
      },
      h3: {
        fontWeight: 'bold'
      },
      h4: {
        fontWeight: 'bold'
      },
      h5: {
        fontWeight: 'bold'
      },
      h6: {
        fontWeight: 'bold'
      },

    },

    components: {
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          size: 'small'
        }
      }
    }
  })

  document.getElementById('root')?.setAttribute('data-theme', appTheme.palette.mode)
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline/>
      {children}
    </ThemeProvider>
  )
}

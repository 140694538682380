import { Intervals, Locales } from '../constants/constants'
import { PaletteMode } from '@mui/material'
import { Platform, Tokens } from '../types/models/user'
import { ChartConfig, ChartType } from '../types/models/chart'

const ACCESS_KEY = 'jwt-access-token'
const REFRESH_KEY = 'jwt-refresh-token'
const EXPIRES_KEY = 'jwt-expires'

const CHART_TYPE_KEY = 'chart-config-type'
const CHART_INTERVAL_KEY = 'chart-config-interval'
const CHART_HEIGHT_KEY = 'chart-config-height'
const CHART_NORMALIZE_KEY = 'chart-config-normalize'
const SELECTED_CHARTS_KEY = 'chart-config-selected-charts'

const NEWS_NOTIFICATIONS_ENABLED_CATEGORY_NAMES = 'news-notifications-enabled-category-names'

const THEME_MODE_KEY = 'app-theme'
const LOCALE_KEY = 'app-locale'

const CURRENT_PLATFORM_NAME = 'current-platform-name'

export const localStorageService = {
  setTokens: ({ refreshToken, accessToken, expiresDate }: Tokens) => {
    localStorage.setItem(ACCESS_KEY, accessToken)
    localStorage.setItem(REFRESH_KEY, refreshToken)
    localStorage.setItem(EXPIRES_KEY, expiresDate)
  },

  removeAuthData: () => {
    localStorage.removeItem(ACCESS_KEY)
    localStorage.removeItem(REFRESH_KEY)
    localStorage.removeItem(EXPIRES_KEY)
  },

  getAccessToken: () => {
    return localStorage.getItem(ACCESS_KEY)
  },

  getRefreshToken: () => {
    return localStorage.getItem(REFRESH_KEY)
  },

  getTokenExpiresDate: () => {
    return localStorage.getItem(EXPIRES_KEY)
  },

  setChartConfig: (chartConfig: ChartConfig) => {
    if (chartConfig.chartType) localStorage.setItem(CHART_TYPE_KEY, chartConfig.chartType)
    if (chartConfig.interval) localStorage.setItem(CHART_INTERVAL_KEY, chartConfig.interval)
    if (chartConfig.height) localStorage.setItem(CHART_HEIGHT_KEY, String(chartConfig.height))
    if (chartConfig.normalize !== undefined) localStorage.setItem(CHART_NORMALIZE_KEY, String(chartConfig.normalize))
    if (chartConfig.selectedCharts) localStorage.setItem(SELECTED_CHARTS_KEY, JSON.stringify(chartConfig.selectedCharts))
  },

  getChartType: (): ChartType | null => {
    return localStorage.getItem(CHART_TYPE_KEY) as ChartType
  },

  getChartInterval: (): Intervals | null => {
    return localStorage.getItem(CHART_INTERVAL_KEY) as Intervals
  },

  getChartHeight: (): number | null => {
    return Number(localStorage.getItem(CHART_HEIGHT_KEY)) || null
  },

  getChartNormalize: (): boolean => {
    return JSON.parse(localStorage.getItem(CHART_NORMALIZE_KEY) || 'false')
  },

  getSelectedCharts: (): string[] => {
    return JSON.parse(localStorage.getItem(SELECTED_CHARTS_KEY) || '[]')
  },

  setTheme: (theme: PaletteMode) => {
    localStorage.setItem(THEME_MODE_KEY, theme)
  },

  getTheme: () => {
    return localStorage.getItem(THEME_MODE_KEY) as PaletteMode || null
  },

  setLocale: (locale: Locales) => {
    localStorage.setItem(LOCALE_KEY, locale)
  },

  getLocale: (): Locales => {
    return localStorage.getItem(LOCALE_KEY) as Locales
  },

  setCurrentPlatformName: (platform: Platform) => {
    localStorage.setItem(CURRENT_PLATFORM_NAME, platform.name)
  },

  getCurrentPlatformName: (): string | null => {
    return localStorage.getItem(CURRENT_PLATFORM_NAME)
  },

  getNewsNotificationsCategories: (): string[] => {
    try {
      return JSON.parse(localStorage.getItem(NEWS_NOTIFICATIONS_ENABLED_CATEGORY_NAMES) || '[]')
    } catch (error) {
      return []
    }
  },

  setNewsNotificationsCategories: (categoryNames: string[]) => {
    try {
      localStorage.setItem(NEWS_NOTIFICATIONS_ENABLED_CATEGORY_NAMES, JSON.stringify(categoryNames))
    } catch (error) {
      localStorage.setItem(NEWS_NOTIFICATIONS_ENABLED_CATEGORY_NAMES, '[]')
    }
  }

}

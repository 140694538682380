import { Box, CircularProgress, Pagination } from '@mui/material'
import { ChangeEvent, FC, useState } from 'react'
import { usePagination } from '../../hooks/usePagination'
import { useNewsState } from '../../store/news/slice'
import { Tag } from '../../types/models/news'
import { NewsCard } from './newsCard/newsCard'
import { P2 } from '../../common/typography'
import { SortButton } from '../../common/sortButton'
import { useSort } from '../../hooks/useSort'

interface NewsListProps {
  searchString: string
  searchTag: Tag | null
}

export const NewsList: FC<NewsListProps> = ({ searchString, searchTag }) => {
  const { news, loading: newsLoading } = useNewsState()
  const [page, setPage] = useState(1)


  const { sortedItems, sortBy, handleSort } = useSort(news, 'createdDate', 'desc')

  const displayedNews = sortedItems
    .filter(n => {
      if (searchTag) {
        return n.tags?.find(tag => tag.name === searchTag.name)
      }
      const searchLowerCase = searchString.toLowerCase().trim()
      const symbolMatch = n.symbol.toLowerCase().includes(searchLowerCase)
      const tagsMatch = n.tags?.find(tag => tag.name.toLowerCase().includes(searchLowerCase))
      return symbolMatch || tagsMatch
    })

  const { pageCount, items: paginateItems } = usePagination(displayedNews, page, 20)

  const handleSetPage = (_: ChangeEvent<unknown>, page: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setPage(page)
  }


  if (newsLoading) return <Box display="flex" justifyContent="center" mt={6}><CircularProgress/></Box>

  return (
    <div>
      <Box display="flex" alignItems="center" mb="10px">
        <P2 color="var(--accent-gray)" mr="10px">Sort by</P2>
        <SortButton
          order={sortBy?.order}
          selected={sortBy?.path === 'createdDate'}
          onClick={() => handleSort('createdDate')}
        >
          Date
        </SortButton>
        <SortButton
          order={sortBy?.order}
          selected={sortBy?.path === 'predictionPercent'}
          onClick={() => handleSort('predictionPercent')}
        >
          Price change
        </SortButton>
      </Box>
      {paginateItems.map(n => <NewsCard key={n.id} news={n} sx={{ mb: 4 }}/>)}
      <Box display="flex" justifyContent="center" my="40px">
        <Pagination count={pageCount} variant="outlined" page={page} onChange={handleSetPage}/>
      </Box>
    </div>
  )
}

import { ChangeEvent, FC, SyntheticEvent, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Dialog,
  InputLabelProps,
  MenuItem,
  Paper,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import { Bot } from '../../../types/models/bot'
import { P1 } from '../../../common/typography'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TButton } from '../../../common/tButton'
import { useMarketState } from '../../../store/market/slice'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../store/store'
import { Intervals } from '../../../constants/constants'
import { createBot, editBot } from '../../../store/bot/actions'

interface EditBotDialogProps {
  open: boolean
  onClose: () => void
  bot?: Bot
}

interface Inputs {
  symbol: string
  title: string
  type: string
  interval: Intervals
  short: boolean
  long: boolean
  trailingStop: string
}

export const EditBotDialog: FC<EditBotDialogProps> = ({ open, onClose, bot }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { markets, getMarket } = useMarketState()
  const { register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm<Inputs>({
    defaultValues: {
      symbol: bot?.symbol,
      title: bot?.title,
      trailingStop: bot?.trailingStop,
      type: bot?.type,
      interval: bot?.interval || Intervals.MINUTE,
      short: bot?.short || true,
      long: bot?.long || false
    }
  })
  const marketsOptions = markets.map(market => market.symbol)

  const [trailingStopOpen, setTrailingStopOpen] = useState(!!bot?.trailingStop)

  const DEFAULT_TICK_SIZE = 0.01
  const [marketTickSize, setMarketTickSize] = useState<number>(bot ? (getMarket(bot.symbol)?.tickSize || DEFAULT_TICK_SIZE) : DEFAULT_TICK_SIZE)

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!trailingStopOpen) {
      data.trailingStop = ''
    }
    if (bot) {
      dispatch(editBot({ ...bot, ...data }))
    } else {
      dispatch(createBot(data))
    }
    handleClose()
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  const onChangeSymbol = (e: SyntheticEvent, newSymbol: string | null) => {
    if (!newSymbol) return
    const market = getMarket(newSymbol)
    if (market) setMarketTickSize(market.tickSize)
    else setMarketTickSize(DEFAULT_TICK_SIZE)
  }

  const handleChangeShortOrLong = (event: ChangeEvent<HTMLInputElement>) => {
    const type = event.target.name as 'short' | 'long'
    const value = event.target.checked
    const oppositeSwitchType = type === 'short' ? 'long' : 'short'
    if (!value && !watch(oppositeSwitchType)) {
      setValue(oppositeSwitchType, true)
    }
    setValue(type, value)
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Paper sx={{ p: 4 }}>

        <P1 variant="h5" fontWeight="lighter" mb={2} className="border-b">
          {bot ? 'Bot modification' : 'Create new bot'}
        </P1>

        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <P1>Market</P1>
            <Autocomplete
              disablePortal
              clearOnEscape
              noOptionsText={t('Not found')}
              autoComplete
              options={marketsOptions}
              defaultValue={bot?.symbol}
              onChange={onChangeSymbol}
              size="small" renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={params.InputLabelProps as Partial<InputLabelProps<'label'>>}
                {...register('symbol', { required: true })}
                error={!!errors.symbol}
              />
            )}
            />
          </Box>

          <Box mb={2}>
            <P1>Title</P1>
            <TextField fullWidth size="small"  {...register('title', { required: true })} error={!!errors.title}/>
          </Box>

          <Box mb={2}>
            <P1>Model type</P1>
            <TextField
              fullWidth
              size="small"
              select
              defaultValue={bot?.type || 'type1'}
              {...register('type', { required: true })}
              error={!!errors.type}
            >
              <MenuItem value="type1">type1</MenuItem>
              <MenuItem value="type2">type2</MenuItem>
              <MenuItem value="type3">type3</MenuItem>
              <MenuItem value="type4">type4</MenuItem>
            </TextField>
          </Box>

          <Box mb={2}>
            <P1>Timeframe</P1>
            <ToggleButtonGroup exclusive value={watch('interval')} onChange={(_, value) => setValue('interval', value)}>
              {Object.values(Intervals).map(interval => (
                <ToggleButton key={interval} value={interval}>{interval}</ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          <Box mb={2} display="flex">
            <Typography alignSelf="center">short</Typography>
            <Switch checked={watch('short')} name="short" onChange={handleChangeShortOrLong}/>
            <Typography alignSelf="center" ml={3}>long</Typography>
            <Switch checked={watch('long')} name="long" onChange={handleChangeShortOrLong}/>
          </Box>

          <Accordion sx={{ width: '100%', mb: 2 }} expanded={trailingStopOpen}>
            <AccordionSummary>
              <Typography alignSelf="center" mr="auto">Trailing stop Loss</Typography>
              <Switch checked={trailingStopOpen} onChange={e => setTrailingStopOpen(e.target.checked)}/>
            </AccordionSummary>
            <AccordionDetails>
              <TextField size="small" inputProps={{ step: marketTickSize }} fullWidth {...register('trailingStop')}
                         error={!!errors.trailingStop} sx={{ mr: 1 }}
                         type="number"/>
            </AccordionDetails>
          </Accordion>

          <Box display="flex" justifyContent="space-between">
            <TButton onClick={handleClose} color="inherit">Cancel</TButton>
            <TButton type="submit">Save</TButton>
          </Box>
        </Box>

      </Paper>
    </Dialog>
  )
}

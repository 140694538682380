import { FC } from 'react'
import { Box, Button, SxProps } from '@mui/material'
import { useNewsState } from '../../store/news/slice'
import { P2 } from '../../common/typography'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { Tag } from '../../types/models/news'

interface TagListProps {
  sx?: SxProps
  activeTag: Tag | null
  onChangeActiveTag?: (tag: Tag) => void
}

export const TagList: FC<TagListProps> = ({ sx, activeTag, onChangeActiveTag }) => {
  const { tags } = useNewsState()
  const uniqueTags: Tag[] = tags.reduce((acc, tag) => acc.find(t => t.name === tag.name) ? acc : [...acc, tag], [] as Tag[])
  let tagsLength = 4
  if (useBreakpoint('xs')) tagsLength = 4
  if (useBreakpoint('md')) tagsLength = 6


  const handleClickTag = (tag: Tag) => {
    if (onChangeActiveTag) {
      onChangeActiveTag(tag)
    }
  }
  return (
    <Box display="flex" sx={sx} gap="10px" flexWrap="wrap" alignItems="center">
      {uniqueTags.slice(0, tagsLength).map(tag => (
        <Button
          key={tag.id}
          sx={{
            p: '10px',
            borderRadius: '10px',
            bgcolor: activeTag?.id === tag.id ? 'var(--accent-orange-hue)' : undefined,
            '&:hover': { bgcolor: activeTag?.id === tag.id ? 'var(--accent-orange-hue)' : undefined },
            color: activeTag?.id === tag.id ? 'var(--main-text)' : 'inherit'
          }}
          onClick={() => handleClickTag(tag)}
        >
          <P2 key={tag.id} textTransform="none">#{tag.name}</P2>
        </Button>
      ))}
      {tagsLength < tags.length && (
        <P2 fontWeight="600" sx={{ textDecoration: 'underline' }}>See more</P2>
      )}
    </Box>
  )
}

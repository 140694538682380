import { FC } from 'react'
import { Box, TextField } from '@mui/material'
import { Link } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useAppDispatch } from '../../store/store'
import { checkRecaptcha, signup } from '../../store/auth/actions'
import { env } from '../../constants/envVars'
import Recaptcha from 'react-google-recaptcha'
import { useAuthState } from '../../store/auth/slice'
import { P1 } from '../../common/typography'
import { useTranslation } from 'react-i18next'
import { TButton } from '../../common/tButton'

interface Inputs {
  email: string
  password: string
  confirmPassword: string
  name: string
}

export const SignupPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { enqueueSnackbar: snackbar } = useSnackbar()
  const { isRecaptchaSuccess } = useAuthState()
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = ({ email, password, name, confirmPassword }) => {
    if (!email.includes('@')) return snackbar('Email is invalid')
    if (confirmPassword !== password) return snackbar('The password is not equal to the confirm password')
    dispatch(signup({ email, password, name }))
  }
  return (
    <>
      <Box mb={2} textAlign="center">
        <P1 variant="h4" mb={1}>Create Account!</P1>
      </Box>
      <Box component="form" width={300} onSubmit={handleSubmit(onSubmit)}>
        <TextField fullWidth label={t('Name')} {...register('name', { required: true })}
                   error={!!errors.name}
                   variant="standard"
                   margin="normal"/>
        <TextField fullWidth label="Email" {...register('email', { required: true })} error={!!errors.email}
                   variant="standard"
                   margin="normal"/>
        <TextField fullWidth label={t('Password')} {...register('password', { required: true })}
                   error={!!errors.password}
                   variant="standard"
                   type="password"
                   margin="normal"
                   autoComplete="off"/>
        <TextField fullWidth label={t('Confirm your password')} {...register('confirmPassword', { required: true })}
                   variant="standard"
                   error={!!errors.confirmPassword}
                   type="password"
                   margin="normal"
                   autoComplete="off"/>
        <Recaptcha sitekey={env.RECAPTCHA_KEY} onChange={(token) => dispatch(checkRecaptcha(token))}
                   hidden={isRecaptchaSuccess}/>
        <Box ml="auto" mt={1} width="min-content" hidden={!isRecaptchaSuccess}>
          <TButton variant="outlined" type="submit">Create</TButton>
        </Box>
      </Box>
      <Box display="flex" mt={2}>
        <P1 fontSize="medium">Already have an account?</P1>
        <Link to="/auth/login" className="link">
          <P1 fontSize="medium" ml={1}>Login</P1>
        </Link>
      </Box>
    </>
  )
}

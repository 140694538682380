import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AuthReducer } from './auth/slice'
import { MarketReducer } from './market/slice'
import { ChartReducer } from './chart/slice'
import { AppReducer } from './app/slice'
import { BotReducer } from './bot/slice'
import { PlatformReducer } from './platform/slice'
import { NewsReducer } from './news/slice'

export const store = configureStore({
  reducer: {
    app: AppReducer,
    auth: AuthReducer,
    market: MarketReducer,
    chart: ChartReducer,
    bot: BotReducer,
    platform: PlatformReducer,
    news: NewsReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type GetState = () => RootState
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

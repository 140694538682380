import { FC } from 'react'
import { ButtonProps } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { KitButton } from './ui-kit/kitButton'

export const TButton: FC<ButtonProps> = ({ children, ...props }) => {
  const { t } = useTranslation()
  return (
    <KitButton {...props}>
      <Trans t={t}>
        {children}
      </Trans>
    </KitButton>
  )
}

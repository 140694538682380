import { AppDispatch, GetState } from '../store'
import { authService } from '../../services/authService'
import { localStorageService } from '../../services/localStorageService'
import { LoginBody, SignupBody } from '../../types/requests/authRequestTypes'
import { ProviderType } from '../../apis/firebase/firebase'
import { firebaseService } from '../../apis/firebase/firebaseService'
import { isAxiosError } from 'axios'
import { AuthProviderName } from '../../types/models/user'
import { authActions } from './slice'

const errorHandler = (error: any, dispatch: AppDispatch) => {
  dispatch(authActions.setLoading(false))
  if (error.code === 'auth/account-exists-with-different-credential') {
    return dispatch(authActions.setError('The account is already registered using another service'))
  }
  if (isAxiosError(error)) {
    return dispatch(authActions.setError(error.response?.data.detail || error.response))
  }
  dispatch(authActions.setError('Unexpected error'))

}

export const signup = (payload: SignupBody) => async (dispatch: AppDispatch, getState: GetState) => {
  if (!getState().auth.isRecaptchaSuccess) return
  try {
    dispatch(authActions.setLoading(true))
    const data = await authService.signup(payload)
    localStorageService.setTokens(data.tokens)
    dispatch(authActions.setCurrentUser(data.user))
    dispatch(authActions.setSubscription(data.subscription))
    dispatch(authActions.setLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}

export const login = (payload: LoginBody) => async (dispatch: AppDispatch, getState: GetState) => {
  try {
    if (!getState().auth.isRecaptchaSuccess) return
    dispatch(authActions.setLoading(true))
    const data = await authService.login(payload)
    localStorageService.setTokens(data.tokens)
    dispatch(authActions.setCurrentUser(data.user))
    dispatch(authActions.setSubscription(data.subscription))
    dispatch(authActions.setLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}

export const autologin = () => async (dispatch: AppDispatch) => {
  try {
    const accessToken = localStorageService.getAccessToken()
    if (!accessToken) return dispatch(authActions.setLoading(false))
    const data = await authService.autologin()
    dispatch(authActions.setCurrentUser(data.user))
    dispatch(authActions.setSubscription(data.subscription))
    dispatch(authActions.setLoading(false))
  } catch (error) {
    localStorageService.removeAuthData()
    errorHandler(error, dispatch)
  }
}

export const checkRecaptcha = (token?: string | null) => async (dispatch: AppDispatch) => {
  if (!token) return
  try {
    const { success } = await authService.checkRecaptcha(token)
    dispatch(authActions.setIsRecaptchaSuccess(success))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}

export const authByProvider = (provider: ProviderType, providerName: AuthProviderName) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(authActions.setLoading(true))
      const providerData = await firebaseService.authByProvider(provider, providerName)
      const data = await authService.authByProvider(providerData)
      localStorageService.setTokens(data.tokens)
      dispatch(authActions.setCurrentUser(data.user))
      dispatch(authActions.setSubscription(data.subscription))
      dispatch(authActions.setLoading(false))
    } catch (error) {
      errorHandler(error, dispatch)
    }
  }
}

export const startTrial = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(authActions.setLoading(true))
    const subscription = await authService.startTrial()
    dispatch(authActions.setSubscription(subscription))
    dispatch(authActions.setLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}

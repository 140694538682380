import { forwardRef } from 'react'
import { Text, TextProps } from './text'

interface H2Props extends TextProps {
}

export const H2 = forwardRef<HTMLParagraphElement, H2Props>(({ children, ...props }, ref) => {
  return (
    <Text
      ref={ref}
      fontSize={{ xs: '30px', sm: '45px', lg: '60px' }}
      lineHeight="120%"
      fontWeight="600"
      fontFamily="'Prompt', sans-serif;"
      {...props}
    >
      {children}
    </Text>
  )
})

import { useSnackbar } from 'notistack'

export const useNotify = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const showConfirm = (message: string, onConfirm: () => void) => {
    enqueueSnackbar(message, {
      variant: 'confirm',
      onConfirm
    })
  }

  return { snackbar: enqueueSnackbar, closeSnackbar, showConfirm }
}

import { AppDispatch } from '../store'
import { addBot, setBot, setBotError, setBotLoading, setBotMarkers, setBots } from './slice'
import { botService } from '../../services/botService'
import { Bot } from '../../types/models/bot'


const errorHandler = (error: any, dispatch: AppDispatch) => {
  console.log(error)
  dispatch(setBotError('ERROR'))
  dispatch(setBotLoading(false))
}

export const loadBots = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setBotLoading(true))
    const bots = await botService.getAll()
    dispatch(setBots(bots))
    dispatch(setBotLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}

export const createBot = (payload: Omit<Bot, 'id' | 'userId'>) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setBotLoading(true))
    const newBot = await botService.create({ ...payload })
    dispatch(addBot(newBot))
    dispatch(setBotLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}

export const editBot = (bot: Bot) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setBotLoading(true))
    const editedBot = await botService.edit(bot)
    dispatch(setBot(editedBot))
    dispatch(setBotLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}

export const loadBotMarkers = (botId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setBotLoading(true))
    const markers = await botService.getMarkers(botId)
    dispatch(setBotMarkers(markers))
    dispatch(setBotLoading(false))
  } catch (error) {
    errorHandler(error, dispatch)
  }
}

import { FC, useState } from 'react'
import { News, NewsPredictionTimeLabels } from '../../../types/models/news'
import { Box, Dialog, SxProps, useTheme } from '@mui/material'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import './styles.scss'
import { H3, P2 } from '../../../common/typography'


interface NewsCardProps {
  news: News
  sx?: SxProps
}

export const NewsCard: FC<NewsCardProps> = ({ news, sx }) => {
  const theme = useTheme().palette.mode
  const formattedDate = new Date(news.createdDate).toLocaleString()
  const [fullChartOpen, setFullChartOpen] = useState(false)
  const chartImage = {
    light: news.chartImageLight || news.chartImageDark,
    dark: news.chartImageDark || news.chartImageLight
  }[theme]

  let predictionTrend = ''
  let predictionColor = undefined
  if (news.predictionPercent === 0) {
    predictionTrend = 'Calm'
  } else if (news.predictionPercent > 0) {
    predictionTrend = 'Positive'
    predictionColor = 'var(--accent-positive)'
  } else if (news.predictionPercent < 0) {
    predictionTrend = 'Negative'
    predictionColor = 'var(--accent-negative)'
  }
  return (
    <Box sx={sx} className="news-card">
      <Box className="news-chart-image-wrapper" data-theme={theme} onClick={() => setFullChartOpen(true)}>
        <img className="news-chart-image" src={chartImage} alt="chart"/>
        <Box className="news-chart-icon"><OpenInFullIcon color="action"/></Box>
      </Box>

      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <H3 mb="10px">Analysis Summary</H3>
          <P2 mb="10px">{news.text}</P2>

          <H3 mb="10px">AI Trend Prediction ({news.symbol})</H3>
          <P2 mb="10px" untranslate>
            {predictionTrend},&nbsp;
            <P2 component="span" color={predictionColor} untranslate>{news.predictionPercent}%</P2>,&nbsp;
            ${news.predictionPrice},&nbsp;
            in the coming {NewsPredictionTimeLabels[news.predictionTime] || news.predictionTime}
          </P2>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexWrap="wrap">
            {news?.tags?.map(tag => (
              <P2 key={tag.id} color="var(--secondary)" mr="10px">#{tag.name}</P2>
            ))}
          </Box>
          <P2 color="var(--accent-gray)" minWidth="max-content" alignSelf="end">{formattedDate}</P2>
        </Box>

      </Box>

      <Dialog open={fullChartOpen} onClose={() => setFullChartOpen(false)} fullWidth maxWidth="lg">
        <Box>
          <img src={chartImage} width="100%" alt="full-cart"/>
        </Box>
      </Dialog>
      </Box>
  )
}

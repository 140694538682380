import axios from 'axios'
import { localStorageService } from './localStorageService'
import { authService } from './authService'
import { convertKeysToCamelCase, convertKeysToSnakeCase } from '../utils/utils'
import { env } from '../constants/envVars'

export const api = axios.create({
  baseURL: env.API_ENDPOINT
})

api.interceptors.request.use(async (config) => {
  if (!(config.data instanceof FormData)) {
    config.data = convertKeysToSnakeCase(config.data)
  }

  if (config.headers['ignore-interceptors']) {
    config.headers['ignore-interceptors'] = undefined
    return config
  }
  const expiresDate = localStorageService.getTokenExpiresDate() || ''
  const isExpired = new Date(expiresDate).getTime() < Date.now()
  const refreshToken = localStorageService.getRefreshToken()
  if (refreshToken && isExpired) {
    const { tokens } = await authService.refresh({ token: refreshToken })
    localStorageService.setTokens({
      refreshToken: tokens.refreshToken,
      expiresDate: tokens.expiresDate,
      accessToken: tokens.accessToken
    })
  }
  const accessToken = localStorageService.getAccessToken()
  if (accessToken) {
    config.headers.Authorization = 'Bearer ' + accessToken
  }
  return config
}, error => {
  return Promise.reject(error)
})

api.interceptors.response.use(res => {
  res.data = convertKeysToCamelCase(res.data)
  return res
})

import { FC } from 'react'
import { AppContainer } from '../../common/appContainer'
import { H2, H3, P2 } from '../../common/typography'


export const CookiesPolicyPage: FC = () => {
  return (
    <AppContainer mt="40px" mb="100px">
      <H2 mb="40px">Cookies policy</H2>
      <H3 untranslate mb="10px">How we use cookies and other similar technology on this website.</H3>
      <P2 untranslate>We can place cookies and other similar technology on your device, including mobile device, in
        accordance with your preferences set on our cookie setting center. Depending on your settings in our cookie
        consent manager on your mobile device, the following information may be collected through cookies or similar
        technology: your unique device identifier, mobile device IP address, information about your device’s operating
        system, mobile carrier and your location information (to the extent permissible under applicable law).</P2>

      <H3 untranslate mt="20px" mb="10px">What are cookies?</H3>
      <P2 untranslate>Cookies are small text files that are placed into user’s device when you visit a website,
        downloaded to your computer or mobile device when you visit a site and allow a site to recognize your device.
        Cookies stores information about the user’s visit, which may include content viewed, language preference, time
        and duration of each visit and advertisement accessed. Cookies managed by us only are called “first party
        cookies” whereas cookies from third parties are called “third party cookies”.</P2>

      <H3 untranslate mt="20px" mb="10px">Why do we use cookies and similar technologies?</H3>
      <P2 untranslate>Cookies are a useful mechanism that do a lot of different jobs, such as letting you navigate
        between pages efficiently, remembering your preferences and generally improving the user experience. They can
        help to ensure that the advertisements you see online are more relevant to you and your interests and enable us
        to identify your preferences. There are different set of cookies, and we will explain that later in this
        document.</P2>

      <H3 untranslate mt="20px" mb="10px">Does we use cookies for marketing and analytics?</H3>
      <P2 untranslate mb="10px">Yes, we may use information collected from our cookies to identify user behaviour and to
        serve content and offers based on your profile, and for the other purposes described below, to the extent
        legally permissible in certain jurisdictions.</P2>
      <P2 untranslate>In other cases, we can associate cookie information (including information from cookies placed via
        our advertisements on third party sites) with an identifiable individual.</P2>

      <H3 untranslate mt="20px" mb="10px">Do you use any cookies from third party companies?</H3>
      <P2 untranslate>Some cookies, web beacons and other tracking and storage technologies that we use are from third
        party companies (third party cookies) to provide us with web analytics and intelligence about our sites which
        may also be used to provide measurement services and target ads.</P2>

      <H3 untranslate mt="20px" mb="10px">What if I don’t want cookies or similar tracking technologies?</H3>
      <P2 untranslate mb="10px">If you want to remove existing cookies from your device, you can do this using your
        browser options.</P2>
      <P2 untranslate>If you want to block cookies from being placed on your device, click "Reject All". Nevertheless,
        please consider that deleting and blocking cookies may have an impact on your user experience.</P2>

    </AppContainer>
  )
}

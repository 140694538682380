import { api } from './httpService'
import {
  AuthByProviderBody,
  AuthResponse,
  AutologinResponse,
  CheckRecaptchaResponse,
  LoginBody,
  RefreshTokenBody,
  RefreshTokenResponse,
  SignupBody
} from '../types/requests/authRequestTypes'
import { Subscription } from '../types/models/user'

export const authService = {
  signup: async (payload: SignupBody): Promise<AuthResponse> => {
    const { data } = await api.post('/auth/signup', payload, { headers: { 'ignore-interceptors': 'ignore' } })
    return data
  },
  login: async (payload: LoginBody): Promise<AuthResponse> => {
    const { data } = await api.post('/auth/login', payload, { headers: { 'ignore-interceptors': 'ignore' } })
    return data
  },
  refresh: async (payload: RefreshTokenBody): Promise<RefreshTokenResponse> => {
    const { data } = await api.post('/auth/refresh_token', payload, { headers: { 'ignore-interceptors': 'ignore' } })
    return data
  },
  autologin: async (): Promise<AutologinResponse> => {
    const { data } = await api.post('/auth/autologin', { headers: { 'ignore-interceptors': 'ignore' } })
    return data
  },
  checkRecaptcha: async (token: string): Promise<CheckRecaptchaResponse> => {
    const { data } = await api.post('/auth/recaptcha', { token }, { headers: { 'ignore-interceptors': 'ignore' } })
    return data
  },
  authByProvider: async (payload: AuthByProviderBody): Promise<AuthResponse> => {
    const { data } = await api.post('auth/auth_with_provider', payload, { headers: { 'ignore-interceptors': 'ignore' } })
    return data
  },
  startTrial: async (): Promise<Subscription> => {
    const { data } = await api.post('/auth/trial')
    return data
  }
}

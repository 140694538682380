import { CandlestickData, SeriesMarker, SingleValueData, UTCTimestamp } from 'lightweight-charts'
import { PRIMARY_COLOR } from '../constants/constants'
import { ChartData, ChartPriceType, ChartType, Marker } from '../types/models/chart'

export const normalizeValue = (firstValue: number | string, value: number | string) => {
  return (Number(value) - Number(firstValue)) / (Number(firstValue) / 100)
}

const formatCandlestickData = (data: ChartData, priceType: ChartPriceType) => {
  const klines = data[priceType]
  return klines.map(([time, open, high, low, close]) => {
    return {
      time: time / 1000 as UTCTimestamp,
      open: Number(open),
      high: Number(high),
      low: Number(low),
      close: Number(close)
    }
  })
}

const formatAndNormalizeCandlestickData = (data: ChartData, priceType: ChartPriceType) => {
  const klines = data[priceType]
  const firstCloseValue = Number(klines[0][4])
  return klines.map(([time, open, high, low, close]) => {
    return {
      time: time / 1000 as UTCTimestamp,
      open: normalizeValue(firstCloseValue, open),
      high: normalizeValue(firstCloseValue, high),
      low: normalizeValue(firstCloseValue, low),
      close: normalizeValue(firstCloseValue, close)
    }
  })
}

const formatLineData = (data: ChartData, priceType: ChartPriceType) => {
  const klines = data[priceType]
  return klines.map(([time, , , , value]) => {
    return {
      time: time / 1000 as UTCTimestamp,
      value: Number(value)
    }
  })
}

const formatAndNormalizeLineData = (data: ChartData, priceType: ChartPriceType) => {
  const klines = data[priceType]
  const values = klines.map(item => Number(item[4]))
  const firstValue = values[0]
  return klines.map(([time, , , , value]) => {
    return {
      time: time / 1000 as UTCTimestamp,
      value: normalizeValue(firstValue, value)
    }
  })
}


export const formatChartData = (chartsData: ChartData[], type: ChartType, normalize: boolean, priceType: ChartPriceType): (CandlestickData[] | SingleValueData[])[] => {
  return chartsData.map(data => {
    if (type === 'candlestick') {
      if (normalize) return formatAndNormalizeCandlestickData(data, priceType)
      else return formatCandlestickData(data, priceType)
    }
    if (type === 'line') {
      if (normalize) return formatAndNormalizeLineData(data, priceType)
      else return formatLineData(data, priceType)
    }
    return []
  })
}

export const formatMarkerData = (markers: Marker[]): SeriesMarker<UTCTimestamp>[] => {

  return markers.map(marker => {
    let shape: SeriesMarker<UTCTimestamp>['shape'] = 'arrowUp'
    let color = PRIMARY_COLOR
    if (marker.action === 'SELL') {
      shape = 'arrowDown'
      color = 'red'
    }
    if (marker.action === 'HOLD') {
      shape = 'circle'
      color = 'gray'
    }
    return {
      time: marker.timestamp / 1000 as UTCTimestamp,
      position: 'inBar',
      shape,
      color,
    }
  })
}



export const env = {
  RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY || '',
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || '',
  WS_API_ENDPOINT: (process.env.REACT_APP_API_ENDPOINT || '').replace('https', 'wss').replace('http', 'ws'),
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY || ''
}

Object.entries(env).forEach(([key, value]) => {
  if (!value) {
    console.error(`Environment variable '${key}' is not defined`)
  }
})

import { FC } from 'react'
import { Button, ButtonProps } from '@mui/material'
import { P2 } from '../typography'

export interface KitButtonProps extends ButtonProps {
}

export const KitButton: FC<KitButtonProps> = ({ sx, children, ...props }) => {
  return (
    <Button
      color="secondary"
      variant="contained"
      sx={{
        textTransform: 'none',
        ...sx
      }}
      {...props}
    >
      <P2>{children}</P2>
    </Button>
  )
}

import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Box, TextField } from '@mui/material'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '../../store/store'
import { checkRecaptcha, login } from '../../store/auth/actions'
import Recaptcha from 'react-google-recaptcha'
import { env } from '../../constants/envVars'
import { useAuthState } from '../../store/auth/slice'
import { useTranslation } from 'react-i18next'
import { P1 } from '../../common/typography'
import { TButton } from '../../common/tButton'


interface Inputs {
  email: string
  password: string
}

export const LoginPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isRecaptchaSuccess } = useAuthState()
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = ({ email, password }) => {
    dispatch(login({ email, password }))
  }


  return (
    <>
      <Box mb={2} textAlign="center">
        <P1 variant="h4" mb={1}>Welcome!</P1>
        <P1>Sign in to your account</P1>
      </Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField variant="standard" label="Email" fullWidth {...register('email', { required: true })}
                   error={!!errors.email}/>
        <TextField variant="standard" label={t('Password')} fullWidth {...register('password', { required: true })}
                   error={!!errors.password}
                   type="password"
                   margin="normal"
                   autoComplete="on"/>

        <Recaptcha sitekey={env.RECAPTCHA_KEY} onChange={(token) => dispatch(checkRecaptcha(token))}
                   hidden={isRecaptchaSuccess}/>
        <Box ml="auto" mt={1} width="min-content" hidden={!isRecaptchaSuccess}>
          <TButton variant="outlined" type="submit">Login</TButton>
        </Box>
      </Box>
      <Box display="flex" mt={2}>
        <P1 fontSize="medium">Don`t have an account?</P1>
        <Link to="/auth/signup" className="link">
          <P1 fontSize="medium" ml={1}>Signup</P1>
        </Link>
      </Box>
    </>
  )
}

import { forwardRef, useCallback, useState } from 'react'
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Box, Dialog } from '@mui/material'
import { P1 } from '../typography'
import { TButton } from '../tButton'


interface ReportCompleteProps extends CustomContentProps {
  onConfirm: () => void
}

export const ConfirmContent = forwardRef<HTMLDivElement, ReportCompleteProps>(({ id, message, onConfirm }, ref) => {
    const { closeSnackbar } = useSnackbar()
    const [open, setOpen] = useState(true)


    const handleDismiss = useCallback(() => {
      setOpen(false)
      closeSnackbar(id)
    }, [id, closeSnackbar, open])

    return (
      <SnackbarContent ref={ref}>
        <Dialog open={open} fullWidth maxWidth="xs">
          <Box p={3}>
            <P1 variant="h5">Are you sure?</P1>
            <Typography pb={1} mb={1} className="border-b">{message}</Typography>
            <Box display="flex" justifyContent="space-between">
              <TButton color="inherit" onClick={handleDismiss}>Cancel</TButton>
              <Button onClick={() => {
                onConfirm()
                handleDismiss()
              }}>Ok</Button>
            </Box>
          </Box>
        </Dialog>
      </SnackbarContent>
    )
  }
)


import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useAppSelector } from '../store'
import { Category, News, Tag } from '../../types/models/news'

export interface NewsState {
  loading: boolean
  error: string | null
  news: News[]
  categories: Category[]
  tags: Tag[]
}

const initialState: NewsState = {
  loading: false,
  error: null,
  news: [],
  categories: [],
  tags: []
}

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload
    },
    setNews: (state, { payload }: PayloadAction<News[]>) => {
      state.news = payload
    },
    setCategories: (state, { payload }: PayloadAction<Category[]>) => {
      state.categories = payload
    },
    setTags: (state, { payload }: PayloadAction<Tag[]>) => {
      state.tags = payload
    },
    addNews: (state, { payload }: PayloadAction<News>) => {
      if (!state.news.find(n => n.id === payload.id)) {
        state.news.push(payload)
      }
    }

  }
})

export const newsActions = newsSlice.actions

export const NewsReducer = newsSlice.reducer

export const useNewsState = () => {
  const state = useAppSelector(s => s.news)
  return { ...state }
}

import { createChart, CrosshairMode, IChartApi } from 'lightweight-charts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export class ChartGenerator {
  private readonly normalizeData: boolean
  private readonly isDark: boolean
  private readonly container: HTMLDivElement
  chart: IChartApi

  constructor(chartContainer: HTMLDivElement, normalizeData: boolean, isDark: boolean) {
    this.container = chartContainer
    this.normalizeData = normalizeData
    this.isDark = isDark
    this.chart = this.getChart()
  }

  private getChart = (): IChartApi => {
    const chartDarkColors = {
      layout: {
        background: { color: '#222' },
        textColor: '#DDD'
      },
      grid: {
        vertLines: { color: '#444' },
        horzLines: { color: '#444' }
      }
    }
    const chart = createChart(this.container, {
      autoSize: true,
      localization: {
        priceFormatter: this.normalizeData ? (p: number) => `${p.toFixed(2)}%` : undefined,
        timeFormatter: (time: number) => dayjs.unix(time).format('DD MMM `YY HH:mm')
      },
      timeScale: { timeVisible: true, secondsVisible: false },
      crosshair: {
        mode: CrosshairMode.Normal
      }
    })

    if (this.isDark) chart.applyOptions({ ...chartDarkColors })
    return chart
  }
}

import { Platform } from '../types/models/user'
import { api } from './httpService'

export const platformService = {

  getAll: async (): Promise<Platform[]> => {
    const { data } = await api.get('/platform/all')
    return data
  },

  getUserPlatforms: async (): Promise<Platform[]> => {
    const { data } = await api.get('/platform/user')
    return data
  },

  assignToUser: async (platformId: number, apiKey: string): Promise<Platform> => {
    const { data } = await api.post('/platform/user', { platformId, apiKey })
    return data
  }
}

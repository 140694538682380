import { forwardRef } from 'react'
import { Text, TextProps } from './text'

interface H3Props extends TextProps {
}

export const H3 = forwardRef<HTMLParagraphElement, H3Props>(({ children, ...props }, ref) => {
  return (
    <Text
      ref={ref}
      fontSize={{ xs: '20px', lg: '25px' }}
      lineHeight="120%"
      fontWeight="600"
      fontFamily="'Montserrat', sans-serif;"
      {...props}
    >
      {children}
    </Text>
  )
})

import { FC } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import { useAppDispatch } from '../../store/store'
import { facebookProvider, githubProvider, googleProvider } from '../../apis/firebase/firebase'
import GoogleIconPng from '../../assets/google-icon.png'
import GithubDarkIconPng from '../../assets/github-dark-icon.png'
import FacebookIconPng from '../../assets/facebook-icon.png'
import { authByProvider } from '../../store/auth/actions'

export const AuthProviderButtons: FC = () => {
  const dispatch = useAppDispatch()

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Tooltip title="Google">
        <IconButton onClick={() => dispatch(authByProvider(googleProvider, 'google'))}>
          <img src={GoogleIconPng} width={30} height={30} alt="google"/>
        </IconButton>
      </Tooltip>
      <Tooltip title="GitHub">
        <IconButton onClick={() => dispatch(authByProvider(githubProvider, 'github'))}>
          <img src={GithubDarkIconPng} width={30} height={30} alt="github"/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Facebook">
        <IconButton onClick={() => dispatch(authByProvider(facebookProvider, 'facebook'))}>
          <img src={FacebookIconPng} width={30} height={30} alt="facebook"/>
        </IconButton>
      </Tooltip>
    </Box>
  )
}

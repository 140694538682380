import { forwardRef } from 'react'
import { Text, TextProps } from './text'

export interface P2Props extends TextProps {
}

export const P2 = forwardRef<HTMLParagraphElement, P2Props>(({ children, ...props }, ref) => {

  return (
    <Text
      ref={ref}
      fontSize={{ xs: '13px', sm: '15px', lg: '18px' }}
      lineHeight="140%"
      fontWeight="400"
      fontFamily="'Montserrat', sans-serif;"
      {...props}
    >
      {children}
    </Text>
  )
})

import { FC } from 'react'
import { Tab, TabProps } from '@mui/material'
import { P2 } from '../typography'

export const KitTab: FC<TabProps> = ({ sx, label, ...props }) => {
  return (
    <Tab
      label={<P2 textTransform="capitalize">{label}</P2>}
      sx={{ color: 'inherit', ...sx }} {...props}
    />
  )
}

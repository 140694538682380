import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaletteMode } from '@mui/material'
import { useAppSelector } from '../store'
import { localStorageService } from '../../services/localStorageService'
import i18n from 'i18next'
import { Locales } from '../../constants/constants'

export interface AppState {
  theme: PaletteMode,
  locale: Locales
}

const initialState: AppState = {
  theme: localStorageService.getTheme() || 'light',
  locale: localStorageService.getLocale() || Locales.EN
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppTheme: (state, { payload }: PayloadAction<PaletteMode>) => {
      state.theme = payload
      localStorageService.setTheme(payload)
    },
    setLocale: (state, { payload }: PayloadAction<Locales>) => {
      localStorageService.setLocale(payload)
      i18n.changeLanguage(payload)
      state.locale = payload
    }
  }
})

export const { setAppTheme, setLocale } = appSlice.actions

export const AppReducer = appSlice.reducer

export const useAppState = () => {
  const state = useAppSelector(state => state.app)
  const isDark = state.theme === 'dark'
  return { ...state, isDark }
}



interface PaginationResult<ItemType> {
  items: ItemType[]
  pageCount: number
}

export const usePagination = <ItemType>(items: ItemType[], page: number, itemsOnPage: number): PaginationResult<ItemType> => {
  const paginationStart = (page - 1) * Number(itemsOnPage)
  const paginationEnd = paginationStart + Number(itemsOnPage)

  const currentItems = items.slice(paginationStart, paginationEnd)
  const pageCount = Math.ceil(items.length / Number(itemsOnPage))

  return { items: currentItems, pageCount }
}

export interface News {
  id: number
  categoryId: number
  symbol: string
  text: string
  predictionPercent: number
  predictionPrice: number
  predictionTime: string
  chartImageLight: string
  chartImageDark: string
  tags?: Tag[]
  createdDate: string
}

export interface Tag {
  id: number
  categoryId: number
  name: string
}

export interface Category {
  id: number
  name: string
}

export const NewsPredictionTimeLabels: Record<string, string> = {
  '1h': 'hour',
  '12h': '12 hours',
  '1w': 'week',
  '1m': 'month'
}

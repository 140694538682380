import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useAppState } from '../../../store/app/slice'
import { useMarketState } from '../../../store/market/slice'
import { formatChartData, formatMarkerData, normalizeValue } from '../../../utils/chartDataFormat'
import { Box, Chip, IconButton, Paper, Typography } from '@mui/material'
import { ChartToolBar } from './chartToolBar'
import { ChartGenerator } from '../../../utils/chartGenerator'
import { useChartState } from '../../../store/chart/slice'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { EditBotDialog } from '../bot/editBotDialog'
import { UTCTimestamp } from 'lightweight-charts'
import { marketService } from '../../../services/marketService'
import { IntervalsInSec } from '../../../constants/constants'
import { Bot } from '../../../types/models/bot'
import { ChartData, Marker } from '../../../types/models/chart'

interface BotChartProps {
  bot: Bot
  chartData: ChartData
  markers: Marker[]
}

export const BotChart: FC<BotChartProps> = ({ bot, chartData, markers }) => {
  const { isDark } = useAppState()
  const { getMarket } = useMarketState()
  const { normalizeData } = useChartState()
  const formattedChartData = useMemo(() => formatChartData([chartData], 'line', normalizeData, 'bid')[0], [chartData, normalizeData])
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const formattedMarkers = formatMarkerData(markers)
  const market = getMarket(chartData.symbol)
  const connectionRef = useRef<WebSocket | null>(null)

  useEffect(() => {
    if (!formattedChartData || !chartContainerRef.current || !market) return
    const chart = new ChartGenerator(chartContainerRef.current, normalizeData, isDark).chart

    const lineSeries = chart.addLineSeries({ priceFormat: { minMove: market?.tickSize } })
    lineSeries.setData(formattedChartData)
    lineSeries.setMarkers(formattedMarkers)
    if (!connectionRef.current) {
      connectionRef.current = marketService.subscribeToLineMarket(market.symbol, (data) => {
        const intervalInSec = IntervalsInSec[bot.interval]
        const time = Math.floor((data.timestamp / 1000) / intervalInSec) * intervalInSec
        const value = normalizeData ? normalizeValue(chartData.ask[0][4], data.ask) : data.ask
        lineSeries.update({ time: time as UTCTimestamp, value })
      })
    }

    return () => {
      chart.remove()
      if (connectionRef.current && connectionRef.current.readyState > 0) {
        connectionRef.current.close()
        connectionRef.current = null
      }
    }

  }, [formattedChartData, isDark, getMarket, chartData, normalizeData, market, bot.interval, formattedMarkers])

  return (
    <Paper sx={{ height: '93vh', px: 1, pt: 1 }} variant="outlined">
      <Box height={65} justifyContent="space-between" pb={1}>
        <Box display="flex" alignItems="center">
          <Typography mr={2}>{bot.symbol}</Typography>
          {bot.short && <Chip label="short" size="small" sx={{ ml: 1 }}/>}
          {bot.long && <Chip label="long" size="small" sx={{ ml: 1 }}/>}
          <Typography mr={2} ml="auto" color="primary" fontWeight="bold">{bot.interval}</Typography>
          <ChartToolBar intervalControl={false} typeControl={false}/>
          <IconButton sx={{ ml: 2 }} onClick={(e) => {
            e.stopPropagation()
            setEditDialogOpen(true)
          }}>
            <SettingsOutlinedIcon fontSize="small"/>
          </IconButton>
          <EditBotDialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} bot={bot}/>
        </Box>
        <Typography mr={2}>{bot.title}</Typography>
      </Box>
      <Box ref={chartContainerRef} sx={{ height: 'calc(100% - 65px)' }}/>
    </Paper>
  )
}

import React, { useEffect } from 'react'
import { AppRoutes } from './app/appRoutes'
import { useAppDispatch } from './store/store'
import { autologin } from './store/auth/actions'
import { Box } from '@mui/material'
import { useAuthState } from './store/auth/slice'
import i18n from 'i18next'
import { useAppState } from './store/app/slice'
import { CookiesPolicyPopUp } from './app/cookiesPolicy/cookiesPolicyPopUp'

function App() {
  const dispatch = useAppDispatch()
  const { isAuth } = useAuthState()
  const { locale } = useAppState()

  useEffect(() => {
    if (!isAuth) {
      dispatch(autologin())
    }
    i18n.changeLanguage(locale)
  }, [dispatch, isAuth])

  return (
    <Box>
      <AppRoutes/>
      <CookiesPolicyPopUp/>
    </Box>
  )
}

export default App
